<template>
<el-tabs v-model="activeName" @tab-click="handleClick">
    <el-tab-pane label="填报须知" name="first">
       <el-form ref="ruleForm" :model="form" label-width="140px" label-position="left">
      <el-row :gutter="20">
        <el-col :span="20">
         <p class="text" v-html="this.tbsm" style="font-size: 18px;line-height:40px"></p>
        </el-col>
      </el-row>
    </el-form>
    </el-tab-pane>
    <el-tab-pane label="表5 教学设备情况统计表" name="second">
  <div style="width:90%;height:auto; margin:0 auto;">
    <el-form ref="ruleForm" :model="form" label-width="180px" label-position="left">
      <el-descriptions title="实验室设备">
         </el-descriptions>
      <el-row :gutter="22">
        <el-col :span="11" v-if="this.primary">
          <el-form-item>
            <div slot="label">小学科学(金额)<font color="red">*</font></div>
            <el-input type="number" :min="0" v-model="form.primarySchoolScienceAmount" @blur="getAmountTotal" @click.native="form.primarySchoolScienceAmount = inputHandleClick(form.primarySchoolScienceAmount)" @keyup.native="form.primarySchoolScienceAmount = oninput(form.primarySchoolScienceAmount,2)">
            <i slot="suffix" style="font-style:normal;">万元</i>
              </el-input>
          </el-form-item>
        </el-col>
         <el-col :span="11" v-if="this.junior||this.senior">
          <el-form-item>
            <div slot="label">中学物理(金额)<font color="red">*</font></div>
            <el-input type="number" :min="0" v-model="form.middleSchoolPhysicsAmount" @blur="getAmountTotal" @click.native="form.middleSchoolPhysicsAmount = inputHandleClick(form.middleSchoolPhysicsAmount)" @keyup.native="form.middleSchoolPhysicsAmount = oninput(form.middleSchoolPhysicsAmount,2)" >
            <i slot="suffix" style="font-style:normal;">万元</i>
              </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="22">
        <el-col :span="11" v-if="this.junior||this.senior">
          <el-form-item>
            <div slot="label">中学化学(金额)<font color="red">*</font></div>
            <el-input type="number" :min="0" v-model="form.middleSchoolChemistryAmount" @blur="getAmountTotal" @click.native="form.middleSchoolChemistryAmount = inputHandleClick(form.middleSchoolChemistryAmount)" @keyup.native="form.middleSchoolChemistryAmount = oninput(form.middleSchoolChemistryAmount,2)">
            <i slot="suffix" style="font-style:normal;">万元</i>
              </el-input>
          </el-form-item>
        </el-col>
         <el-col :span="11" v-if="this.junior||this.senior">
          <el-form-item>
            <div slot="label">中学生物(金额)<font color="red">*</font></div>
            <el-input type="number" :min="0" v-model="form.middleSchoolBiologyAmount" @blur="getAmountTotal" @click.native="form.middleSchoolBiologyAmount = inputHandleClick(form.middleSchoolBiologyAmount)"  @keyup.native="form.middleSchoolBiologyAmount = oninput(form.middleSchoolBiologyAmount,2)">
            <i slot="suffix" style="font-style:normal;">万元</i>
              </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-descriptions title="功能教室设备">
         </el-descriptions>
      <el-row :gutter="22">
        <el-col :span="11">
          <el-form-item>
            <div slot="label">中小学思政(金额)<font color="red">*</font></div>
            <el-input type="number" :min="0" v-model="form.middleSchoolPoliticalAmount" @blur="getAmountTotal" @click.native="form.middleSchoolPoliticalAmount = inputHandleClick(form.middleSchoolPoliticalAmount)" @keyup.native="form.middleSchoolPoliticalAmount = oninput(form.middleSchoolPoliticalAmount,2)">
            <i slot="suffix" style="font-style:normal;">万元</i>
              </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11" v-if="this.junior||this.senior">
          <el-form-item>
            <div slot="label">中学史地(金额)<font color="red">*</font></div>
            <el-input type="number" :min="0" v-model="form.middleSchoolHistoryGeographyAmount" @blur="getAmountTotal" @click.native="form.middleSchoolHistoryGeographyAmount = inputHandleClick(form.middleSchoolHistoryGeographyAmount)" @keyup.native="form.middleSchoolHistoryGeographyAmount = oninput(form.middleSchoolHistoryGeographyAmount,2)">
            <i slot="suffix" style="font-style:normal;">万元</i>
              </el-input>
          </el-form-item>
        </el-col>
        
      </el-row>
       <el-row :gutter="22">
        <el-col :span="11">
          <el-form-item>
            <div slot="label">中小学音乐(金额)<font color="red">*</font></div>
            <el-input type="number" :min="0" v-model="form.middleSchoolMusicAmount" @blur="getAmountTotal" @click.native="form.middleSchoolMusicAmount = inputHandleClick(form.middleSchoolMusicAmount)" @keyup.native="form.middleSchoolMusicAmount = oninput(form.middleSchoolMusicAmount,2)">
            <i slot="suffix" style="font-style:normal;">万元</i>
              </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item>
            <div slot="label">中小学美术(金额)<font color="red">*</font></div>
            <el-input type="number" :min="0" v-model="form.middleSchoolArtsAmount" @blur="getAmountTotal" @click.native="form.middleSchoolArtsAmount = inputHandleClick(form.middleSchoolArtsAmount)" @keyup.native="form.middleSchoolArtsAmount = oninput(form.middleSchoolArtsAmount,2)">
            <i slot="suffix" style="font-style:normal;">万元</i>
              </el-input>
          </el-form-item>
        </el-col>
        
      </el-row>
      <el-row :gutter="22">
        <el-col :span="11"  v-if="this.primary">
          <el-form-item>
            <div slot="label">小学书法(金额)<font color="red">*</font></div>
            <el-input type="number" :min="0" v-model="form.primarySchoolCalligraphyAmount" @blur="getAmountTotal" @click.native="form.primarySchoolCalligraphyAmount = inputHandleClick(form.primarySchoolCalligraphyAmount)" @keyup.native="form.primarySchoolCalligraphyAmount = oninput(form.primarySchoolCalligraphyAmount,2)">
            <i slot="suffix" style="font-style:normal;">万元</i>
              </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item>
            <div slot="label">中小学体育(金额)<font color="red">*</font></div>
            <el-input type="number" :min="0" v-model="form.middleSchoolSportsAmount" @blur="getAmountTotal" @click.native="form.middleSchoolSportsAmount = inputHandleClick(form.middleSchoolSportsAmount)" @keyup.native="form.middleSchoolSportsAmount = oninput(form.middleSchoolSportsAmount,2)">
            <i slot="suffix" style="font-style:normal;">万元</i>
              </el-input>
          </el-form-item>
        </el-col>
        
      </el-row>
       <el-row :gutter="22">
        <el-col :span="11">
          <el-form-item>
            <div slot="label">中小学心理健康(金额)<font color="red">*</font></div>
            <el-input type="number" :min="0" v-model="form.middleSchoolMentalAmount" @blur="getAmountTotal" @click.native="form.middleSchoolMentalAmount = inputHandleClick(form.middleSchoolMentalAmount)" @keyup.native="form.middleSchoolMentalAmount = oninput(form.middleSchoolMentalAmount,2)">
            <i slot="suffix" style="font-style:normal;">万元</i>
              </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item>
            <div slot="label">中小学信息科技(金额)<font color="red">*</font></div>
            <el-input type="number" :min="0" v-model="form.middleSchoolInformationAmount" @blur="getAmountTotal" @click.native="form.middleSchoolInformationAmount = inputHandleClick(form.middleSchoolInformationAmount)" @keyup.native="form.middleSchoolInformationAmount = oninput(form.middleSchoolInformationAmount,2)">
            <i slot="suffix" style="font-style:normal;">万元</i>
              </el-input>
          </el-form-item>
        </el-col>
        
      </el-row>
      
      <el-row :gutter="22">
        <el-col :span="11">
          <el-form-item>
            <div slot="label">中小学劳技/通用技术/综合实践(金额)<font color="red">*</font></div>
            <el-input type="number" :min="0" v-model="form.middleSchoolCurrencyAmount" @blur="getAmountTotal" @click.native="form.middleSchoolCurrencyAmount = inputHandleClick(form.middleSchoolCurrencyAmount)" @keyup.native="form.middleSchoolCurrencyAmount = oninput(form.middleSchoolCurrencyAmount,2)">
            <i slot="suffix" style="font-style:normal;">万元</i>
              </el-input>
          </el-form-item>
        </el-col>
       <el-col :span="11">
          <el-form-item>
            <div slot="label">中小学其他(金额)<font color="red">*</font></div>
            <el-input type="number" :min="0" v-model="form.middleSchoolOtherAmount" @blur="getAmountTotal" @click.native="form.middleSchoolOtherAmount = inputHandleClick(form.middleSchoolOtherAmount)" @keyup.native="form.middleSchoolOtherAmount = oninput(form.middleSchoolOtherAmount,2)">
            <i slot="suffix" style="font-style:normal;">万元</i>
              </el-input>
          </el-form-item>
        </el-col>
      </el-row>
       <el-row :gutter="22">
        <el-col :span="11">
          <el-form-item>
            <div slot="label">中小学网络及外部设备(金额)<font color="red">*</font></div>
            <el-input type="number" :min="0" v-model="form.middleSchoolNetworkAmount" @blur="getAmountTotal" @click.native="form.middleSchoolNetworkAmount = inputHandleClick(form.middleSchoolNetworkAmount)" @keyup.native="form.middleSchoolNetworkAmount = oninput(form.middleSchoolNetworkAmount,2)" >
            <i slot="suffix" style="font-style:normal;">万元</i>
              </el-input>
          </el-form-item>
        </el-col>
       <el-col :span="11">
          <el-form-item>
            <div slot="label">中小学图书馆/阅览室设备(金额)<font color="red">*</font></div>
            <el-input type="number" :min="0" v-model="form.middleSchoolLibraryAmount" @blur="getAmountTotal" @click.native="form.middleSchoolLibraryAmount = inputHandleClick(form.middleSchoolLibraryAmount)" @keyup.native="form.middleSchoolLibraryAmount = oninput(form.middleSchoolLibraryAmount,2)">
            <i slot="suffix" style="font-style:normal;">万元</i>
              </el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="22">
        <el-col :span="11">
          <el-form-item>
            <div slot="label">
              <el-descriptions title="金额合计">
         </el-descriptions></div>
            <el-input v-model="form.amountTotal"  type="number" disabled :min="0">
            <i slot="suffix" style="font-style:normal;">万元</i>
              </el-input>
          </el-form-item>
        </el-col>
      </el-row>
    
     
  
    </el-form>
     <div style="text-align:center; padding-top:10px">
      <el-button type="primary"  @click="saveDraft">保存草稿</el-button>
      <el-button type="primary"  @click="save">保存</el-button>

      <el-button type="danger" @click="closePage">取消</el-button>
    </div>
  </div>
    </el-tab-pane>
</el-tabs>
</template>

<script>

import { Message, MessageBox } from 'element-ui'
import { getDictListByCode } from '@/api/dict'
import { getTableInfo,saveTE } from '@/api/taskSchool'


export default {
  name: '',
  inject: ['getListByAppId'],
  props: { // 第二种方式
    proid: {
      type: String,
      required: true
    },
    tablestate: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      tbsm:"填报说明</br>本显示学校教学设备的配备的基本情况，包含小学科学、中学物理、中学化学、中学生物实验室和其附属用房内和思政、史地、音乐、美术、书法、体育、心理健康、信息科技、劳技/通用/综合实践等，以及表中类未体现的功能教室及其附属用房内的设备；网络及外部设备；图书馆/阅览室设备。</br></br>1.实验室设备和功能教室设备：理科实验室和功能教室及附属用房内的桌、凳、台、柜、厨、通风换气、空调等，以购入的金额为准。</br>2.网络及外部设备：是指不包括计算机在内的投入校园网网络建设的金额之和，以购入时的金额为准；网络及外部设备包括：机柜、服务器、交换机、UPS电源、控制器、路由器、调制解调器（猫）等</br>3.图书馆、阅览室设备：是指学校图书馆、阅览室内所有家具类、办公类设备、流通类、信息化软硬件等，但不包含多媒体及计算机。</br>4.合计：表中前面项目的总和。",
      up1Visible: false,
      up2Visible: false,
      form: {
        userName:'',
        userCompany:''
      },
      xmName: '',
      themeName: '',
      schemaNa: '',
      activeName:'second',
      primary:false,
      junior:false,
      senior:false,
      multipleSelection: [],
      txData: [],
      types: [],
      options: [{
          value: '1',
          label: '小学'
        }, {
          value: '2',
          label: '初中'
        }, {
          value: '3',
          label: '完中'
        },{
          value: '4',
          label: '高中'
        }, {
          value: '5',
          label: '九年一贯'
        },{
          value: '6',
          label: '十二年一贯'
        }]
    }
  },
  created() {
    this.getInfo()
  },
  methods: {
    getAmountTotal(){
      
      this.form.amountTotal= 
          Number(this.form.primarySchoolScienceAmount)+
           Number(this.form.middleSchoolPhysicsAmount)+
           Number(this.form.middleSchoolChemistryAmount)+
           Number(this.form.middleSchoolBiologyAmount)+
           Number(this.form.middleSchoolPoliticalAmount)+
           Number(this.form.middleSchoolHistoryGeographyAmount)+
           Number(this.form.middleSchoolMusicAmount)+
           Number(this.form.middleSchoolArtsAmount)+
           Number(this.form.primarySchoolCalligraphyAmount)+
           Number(this.form.middleSchoolSportsAmount)+
           Number(this.form.middleSchoolMentalAmount)+
           Number(this.form.middleSchoolInformationAmount)+
           Number(this.form.middleSchoolCurrencyAmount)+
           Number(this.form.middleSchoolOtherAmount)+
           Number(this.form.middleSchoolNetworkAmount)+
           Number(this.form.middleSchoolLibraryAmount)
           this.form.amountTotal=this.form.amountTotal.toFixed(2)
           },
    inputHandleClick(v){
      
      if(v==''||v==0||v==0.00){
        return ''
      }
      return v
    },
oninput(val,limit = 0) {
            val = val.replace(/[^\d.]/g, ""); //保留数字
            val = val.replace(/^00/, "0."); //开头不能有两个0
            val = val.replace(/^\./g, "0."); //开头为小数点转换为0.
            val = val.replace(/\.{2,}/g, "."); //两个以上的小数点转换成一个
            val = val.replace(".", "$#$").replace(/\./g, "").replace("$#$", "."); //只保留一个小数点
            /^0\d+/.test(val) ? val = val.slice(1) : ''; //两位以上数字开头不能为0
            const str = '^(\\d+)\\.(\\d{' + limit + '}).*$'
            const reg = new RegExp(str)
            if (limit === 0) {
                // 不需要小数点
                val= val.replace(reg, '$1')
            } else {
                // 通过正则保留小数点后指定的位数
                val= val.replace(reg, '$1.$2')
            }
            return val
        },
     getInfo(){
      var listQuery={}
      listQuery.type="5"
      listQuery.id=this.proid
      getTableInfo(listQuery).then(response => {
        this.form=response.data.teachingEquipment
        var str=response.data.department
        var eduStage=str.split(',')
        for (var i in eduStage) {
        if (eduStage[i] === "0x1") {
          this.primary=true
        }
        if (eduStage[i] === "0x2") {
          this.junior=true
        }
        if (eduStage[i] === "0x4") {
          this.senior=true
        }
        }
      })
    },
    saveDraft(){
       const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
          //小学
         if(!this.form.primarySchoolScienceAmount){
         this.form.primarySchoolScienceAmount=0
        }
        if(!this.form.primarySchoolCalligraphyAmount){
          this.form.primarySchoolCalligraphyAmount=0
        }
         
        
        
       //中小
           if(!this.form.middleSchoolPhysicsAmount){
          this.form.middleSchoolPhysicsAmount=0
        }
         if(!this.form.middleSchoolChemistryAmount){
           this.form.middleSchoolChemistryAmount=0
        }

         if(!this.form.middleSchoolBiologyAmount){
           this.form.middleSchoolBiologyAmount=0
        }

         if(!this.form.middleSchoolHistoryGeographyAmount){
          this.form.middleSchoolHistoryGeographyAmount=0
        }
          
       //所有
          if(!this.form.middleSchoolPoliticalAmount){
           this.form.middleSchoolPoliticalAmount=0
        }
         if(!this.form.middleSchoolMusicAmount){
           this.form.middleSchoolMusicAmount=0
        }
         if(!this.form.middleSchoolArtsAmount){
           this.form.middleSchoolArtsAmount=0
        }
         if(!this.form.middleSchoolSportsAmount){
          this.form.middleSchoolSportsAmount=0
        }
         if(!this.form.middleSchoolMentalAmount){
           this.form.middleSchoolMentalAmount=0 
        }
         if(!this.form.middleSchoolInformationAmount){
          this.form.middleSchoolInformationAmount=0
        }
         if(!this.form.middleSchoolCurrencyAmount){
          this.form.middleSchoolCurrencyAmount=0 
        }
         if(!this.form.middleSchoolOtherAmount){
           this.form.middleSchoolOtherAmount=0
        }
         if(!this.form.middleSchoolNetworkAmount){
           this.form.middleSchoolNetworkAmount=0 
        }
         if(!this.form.middleSchoolLibraryAmount){
          this.form.middleSchoolLibraryAmount=0
        }
         this.form.state='0'
       saveTE(this.form).then(response => {
          Message({
            message: '保存成功',
            type: 'success',
            duration: 5 * 1000
          })
          loading.close()
          // 重新加载表格
          this.getInfo()
        }).catch(response => {
          loading.close()
        })
    },
     save(){
       const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
       //小学
         if(!this.form.primarySchoolScienceAmount){
         this.form.primarySchoolScienceAmount=0
        }
        if(!this.form.primarySchoolCalligraphyAmount){
          this.form.primarySchoolCalligraphyAmount=0
        }
         
        
        
       //中小
           if(!this.form.middleSchoolPhysicsAmount){
          this.form.middleSchoolPhysicsAmount=0
        }
         if(!this.form.middleSchoolChemistryAmount){
           this.form.middleSchoolChemistryAmount=0
        }

         if(!this.form.middleSchoolBiologyAmount){
           this.form.middleSchoolBiologyAmount=0
        }

         if(!this.form.middleSchoolHistoryGeographyAmount){
          this.form.middleSchoolHistoryGeographyAmount=0
        }
          
       //所有
          if(!this.form.middleSchoolPoliticalAmount){
           this.form.middleSchoolPoliticalAmount=0
        }
         if(!this.form.middleSchoolMusicAmount){
           this.form.middleSchoolMusicAmount=0
        }
         if(!this.form.middleSchoolArtsAmount){
           this.form.middleSchoolArtsAmount=0
        }
         if(!this.form.middleSchoolSportsAmount){
          this.form.middleSchoolSportsAmount=0
        }
         if(!this.form.middleSchoolMentalAmount){
           this.form.middleSchoolMentalAmount=0 
        }
         if(!this.form.middleSchoolInformationAmount){
          this.form.middleSchoolInformationAmount=0
        }
         if(!this.form.middleSchoolCurrencyAmount){
          this.form.middleSchoolCurrencyAmount=0 
        }
         if(!this.form.middleSchoolOtherAmount){
           this.form.middleSchoolOtherAmount=0
        }
         if(!this.form.middleSchoolNetworkAmount){
           this.form.middleSchoolNetworkAmount=0 
        }
         if(!this.form.middleSchoolLibraryAmount){
          this.form.middleSchoolLibraryAmount=0
        }

       

        this.form.state='1'

       saveTE(this.form).then(response => {
          Message({
            message: '保存成功',
            type: 'success',
            duration: 5 * 1000
          })
          loading.close()
          // 重新加载表格
          this.getInfo()
        }).catch(response => {
          loading.close()
        })
    },
    closePage() {
      // 调用全局挂载的方法,关闭当前标签页
      //  this.$store.dispatch('tagsView/delView', this.$route)
      this.$router.push({ path: '/super/dataFilling' })
    }
  }
}
</script>
