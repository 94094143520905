<template>
<el-tabs v-model="activeName" @tab-click="handleClick">
    <el-tab-pane label="填报须知" name="first">
       <el-form ref="ruleForm" :model="form" label-width="140px" label-position="left">
      <el-row :gutter="20">
        <el-col :span="20">
         <p class="text" v-html="this.tbsm" style="font-size: 18px;line-height:40px"></p>
        </el-col>
      </el-row>
    </el-form>
    </el-tab-pane>
    <el-tab-pane label="表8 图书馆情况统计" name="second">
  <div style="width:90%;height:auto; margin:0 auto;">
    <el-form ref="ruleForm" :model="form" label-width="120px" label-position="left">
      
       <el-descriptions title="图书馆整体性">
         </el-descriptions>
      <el-row :gutter="22">
        <el-col :span="10">
          <el-form-item>
            <div slot="label">是否独立<font color="red">*</font></div>
             <el-radio v-model="form.libraryIndependent" label="1">是</el-radio>
             <el-radio v-model="form.libraryIndependent" label="2">否</el-radio>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item>
            <div slot="label">面积（㎡）<font color="red">*</font></div>
            <el-input type="number" onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))"  :min="0" v-model="form.libraryMeasure" >
            <i slot="suffix" style="font-style:normal;">㎡</i>
              </el-input>
          </el-form-item>
        </el-col>       
      </el-row>  
      
      <el-descriptions title="阅览室 （含数字阅览）">
         </el-descriptions>
      <el-row :gutter="22">
         <el-col :span="8">
          <el-form-item>
            <div slot="label">间数（间）<font color="red">*</font></div>
            <el-input type="number" onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))"  :min="0" v-model="form.readingRoomNumber" >
            <i slot="suffix" style="font-style:normal;">间</i>
              </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item>
            <div slot="label">面积（㎡）<font color="red">*</font></div>
            <el-input type="number" onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))"  :min="0" v-model="form.readingRoomMeasure" >
            <i slot="suffix" style="font-style:normal;">㎡</i>
              </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item>
            <div slot="label">座位个数（个）<font color="red">*</font></div>
            <el-input type="number" onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))"  :min="0" v-model="form.readingRoomSeat" >
            <i slot="suffix" style="font-style:normal;">个</i>
              </el-input>
          </el-form-item>
        </el-col>
      </el-row>

       <el-descriptions title="藏书室">
         </el-descriptions>
      <el-row :gutter="22">
         <el-col :span="8">
          <el-form-item>
            <div slot="label">间数（间）<font color="red">*</font></div>
            <el-input type="number" onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))"  :min="0" v-model="form.bookRoomNumber" >
            <i slot="suffix" style="font-style:normal;">间</i>
              </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item>
            <div slot="label">面积（㎡）<font color="red">*</font></div>
            <el-input type="number" onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))"  :min="0" v-model="form.bookRoomMeasure" >
            <i slot="suffix" style="font-style:normal;">㎡</i>
              </el-input>
          </el-form-item>
        </el-col>
      </el-row>
       <el-descriptions title="纸质图书基本情况">
         </el-descriptions>
      <el-row :gutter="22">
         <el-col :span="8">
          <el-form-item>
            <div slot="label">总册数（册）<font color="red">*</font></div>
            <el-input type="number" onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))"  :min="0" v-model="form.paperBookTotal" >
            <i slot="suffix" style="font-style:normal;">册</i>
              </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item>
            <div slot="label">总金额（万元）<font color="red">*</font></div>
            <el-input type="number"   :min="0" v-model="form.paperBookAmount" @click.native="form.paperBookAmount = inputHandleClick(form.paperBookAmount)"  @keyup.native="form.paperBookAmount = oninput(form.paperBookAmount,2)">
            <i slot="suffix" style="font-style:normal;">万元</i>
              </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item>
            <div slot="label">纸质图书尚需册数（册）<font color="red">*</font></div>
            <el-input type="number" onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))"  :min="0" v-model="form.paperBookNeed" >
            <i slot="suffix" style="font-style:normal;">册</i>
              </el-input>
          </el-form-item>
        </el-col>
      </el-row>

       
     
  
    </el-form>
     <div style="text-align:center; padding-top:10px">
      <el-button type="primary"  @click="saveDraft">保存草稿</el-button>
      <el-button type="primary"  @click="save">保存</el-button>

      <el-button type="danger" @click="closePage">取消</el-button>
    </div>
  </div>
    </el-tab-pane>
</el-tabs>
</template>

<script>

import { Message, MessageBox } from 'element-ui'
import { getDictListByCode } from '@/api/dict'
import { getTableInfo,saveLSS } from '@/api/taskSchool'


export default {
  name: '',
  inject: ['getListByAppId'],
  props: { // 第二种方式
    proid: {
      type: String,
      required: true
    },
    tablestate: {
      type: String,
      required: true
    }
  },
  data() {
    return {
tbsm:"本表包含中小学图书馆（室）建设和配备的基本情况，包括中小学图书馆整体性、阅览室（含数字阅览）、藏书室和纸质图书基本情况。</br>1.图书馆整体性：图书馆独立是指有独立的图书馆舍，面积单位“㎡”，只保留整数。</br>2.阅览室（含数字阅览）：面积单位“㎡”，只保留整数；坐席个数单位“个”。</br>3.藏书室：面积单位“㎡”，只保留整数。</br>4.纸质图书基本情况</br>（1）“总册数”是指学校图书馆在帐的纸质图书的总册数；</br>（2）“总金额”是指学校图书馆在帐的纸质图书的总金额，单位“万元”，保留小数点后两位；</br>（3）“纸质图书尚需册数”：按照教育部《中小学图书馆（室）规程》中人均藏书量计算，图书馆仍需要补充的册数。</br>",
up1Visible: false,
      up2Visible: false,
      form: {
        userName:'',
        userCompany:''
      },
      xmName: '',
      themeName: '',
      schemaNa: '',
      standard:{
        kxys:1,
        kxstfz:2,
        kxsyfz:3,
        wlys:9,
        wlfz:91,
        hxys:12,
        hxfz:13,
        swys:27,
        swfz:28,
        swtj:29
      },
      activeName:'second',
      multipleSelection: [],
      txData: [],
      types: [],
      options: [{
          value: '1',
          label: '小学'
        }, {
          value: '2',
          label: '初中'
        }, {
          value: '3',
          label: '完中'
        },{
          value: '4',
          label: '高中'
        }, {
          value: '5',
          label: '九年一贯'
        },{
          value: '6',
          label: '十二年一贯'
        }]
    }
  },
  created() {
    this.getInfo()
  },
  methods: {
    inputHandleClick(v){
      
      if(v==''||v==0||v==0.00){
        return ''
      }
      return v
    },
oninput(val,limit = 0) {
            val = val.replace(/[^\d.]/g, ""); //保留数字
            val = val.replace(/^00/, "0."); //开头不能有两个0
            val = val.replace(/^\./g, "0."); //开头为小数点转换为0.
            val = val.replace(/\.{2,}/g, "."); //两个以上的小数点转换成一个
            val = val.replace(".", "$#$").replace(/\./g, "").replace("$#$", "."); //只保留一个小数点
            /^0\d+/.test(val) ? val = val.slice(1) : ''; //两位以上数字开头不能为0
            const str = '^(\\d+)\\.(\\d{' + limit + '}).*$'
            const reg = new RegExp(str)
            if (limit === 0) {
                // 不需要小数点
                val= val.replace(reg, '$1')
            } else {
                // 通过正则保留小数点后指定的位数
                val= val.replace(reg, '$1.$2')
            }
            return val
        },

     getInfo(){
      var listQuery={}
      listQuery.type="8"
      listQuery.id=this.proid
      getTableInfo(listQuery).then(response => {
        this.form=response.data
      })
    },
       save(){
       const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
      
         if(!this.form.libraryIndependent){
          this.form.libraryIndependent=2
        }
         if(!this.form.libraryMeasure){
           this.form.libraryMeasure=0
        }
         if(!this.form.readingRoomNumber){
         this.form.readingRoomNumber=0
        }
         if(!this.form.readingRoomMeasure){
          this.form.readingRoomMeasure=0
        }
         if(!this.form.readingRoomSeat){
          this.form.readingRoomSeat=0
        }
         if(!this.form.bookRoomNumber){
          this.form.bookRoomNumber=0
        }
         if(!this.form.bookRoomMeasure){
          this.form.bookRoomMeasure=0
        }
         if(!this.form.paperBookTotal){
          this.form.paperBookTotal=0
        }
         if(!this.form.paperBookAmount){
          this.form.paperBookAmount=0
        }
         if(!this.form.paperBookNeed){
         this.form.paperBookNeed=0
        }
        
        this.form.state='1'

       saveLSS(this.form).then(response => {
          Message({
            message: '保存成功',
            type: 'success',
            duration: 5 * 1000
          })
          loading.close()
          // 重新加载表格
          this.getInfo()
        }).catch(response => {
          loading.close()
        })
       },
    saveDraft(){
       const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
           if(!this.form.libraryIndependent){
          this.form.libraryIndependent=2
        }
         if(!this.form.libraryMeasure){
           this.form.libraryMeasure=0
        }
         if(!this.form.readingRoomNumber){
         this.form.readingRoomNumber=0
        }
         if(!this.form.readingRoomMeasure){
          this.form.readingRoomMeasure=0
        }
         if(!this.form.readingRoomSeat){
          this.form.readingRoomSeat=0
        }
         if(!this.form.bookRoomNumber){
          this.form.bookRoomNumber=0
        }
         if(!this.form.bookRoomMeasure){
          this.form.bookRoomMeasure=0
        }
         if(!this.form.paperBookTotal){
          this.form.paperBookTotal=0
        }
         if(!this.form.paperBookAmount){
          this.form.paperBookAmount=0
        }
         if(!this.form.paperBookNeed){
         this.form.paperBookNeed=0
        }
         this.form.state='0'
       saveLSS(this.form).then(response => {
          Message({
            message: '保存成功',
            type: 'success',
            duration: 5 * 1000
          })
          loading.close()
          // 重新加载表格
          this.getInfo()
        }).catch(response => {
          loading.close()
        })
    },
    closePage() {
      // 调用全局挂载的方法,关闭当前标签页
      //  this.$store.dispatch('tagsView/delView', this.$route)
      this.$router.push({ path: '/super/dataFilling' })
    }
  }
}
</script>
