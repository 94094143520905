<template>
<el-tabs v-model="activeName" @tab-click="handleClick">
    <el-tab-pane label="填报须知" name="first">
       <el-form ref="ruleForm" :model="form" label-width="140px" label-position="left">
      <el-row :gutter="20">
        <el-col :span="20">
         <p class="text" v-html="this.tbsm" style="font-size: 18px;line-height:40px"></p>
        </el-col>
      </el-row>
    </el-form>
    </el-tab-pane>
    <el-tab-pane label="表9 当年教育技术装备投入情况统计表" name="second">
  <div style="width:90%;height:auto; margin:0 auto;">
    <el-form ref="ruleForm" :model="form" label-width="120px" label-position="left">
     
      <el-descriptions title="当年教育技术装备购置经费（万元）">
         </el-descriptions>
      <el-row :gutter="24">
         <el-col :span="10">
          <el-form-item>
            <div slot="label">总金额<font color="red">*</font></div>
            <el-input type="number" :min="0" v-model="form.acquisitionFundsTotalAmount" @click.native="form.acquisitionFundsTotalAmount = inputHandleClick(form.acquisitionFundsTotalAmount)" @keyup.native="form.acquisitionFundsTotalAmount = oninput(form.acquisitionFundsTotalAmount,2)">
            <i slot="suffix" style="font-style:normal;">万元</i>
              </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item>
            <div slot="label">财政拨款金额<font color="red">*</font></div>
            <el-input type="number" :min="0" v-model="form.acquisitionFundsFinanceAmount" @click.native="form.acquisitionFundsFinanceAmount = inputHandleClick(form.acquisitionFundsFinanceAmount)" @keyup.native="form.acquisitionFundsFinanceAmount = oninput(form.acquisitionFundsFinanceAmount,2)">
            <i slot="suffix" style="font-style:normal;">万元</i>
              </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item>
            <div slot="label">非财政拨款金额<font color="red">*</font></div>
            <el-input type="number" :min="0" v-model="form.acquisitionFundsNoFinanceAmount" @click.native="form.acquisitionFundsNoFinanceAmount = inputHandleClick(form.acquisitionFundsNoFinanceAmount)" @keyup.native="form.acquisitionFundsNoFinanceAmount = oninput(form.acquisitionFundsNoFinanceAmount,2)" >
            <i slot="suffix" style="font-style:normal;">万元</i>
              </el-input>
          </el-form-item>
        </el-col>
       
      </el-row>
     <el-descriptions title="多媒体更新">
         </el-descriptions>
      <el-row :gutter="24">
         <el-col :span="10">
          <el-form-item>
            <div slot="label">套数<font color="red">*</font></div>
            <el-input type="number" :min="0" onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))" v-model="form.multiMediaNumber" >
            <i slot="suffix" style="font-style:normal;">套</i>
              </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item>
            <div slot="label">金额<font color="red">*</font></div>
            <el-input type="number" :min="0" v-model="form.multiMediaAmount" @click.native="form.multiMediaAmount = inputHandleClick(form.multiMediaAmount)" @keyup.native="form.multiMediaAmount = oninput(form.multiMediaAmount,2)" >
            <i slot="suffix" style="font-style:normal;">万元</i>
              </el-input>
          </el-form-item>
        </el-col>        
      </el-row>
     
      <el-descriptions title="计算机更新">
         </el-descriptions>
      <el-row :gutter="24">
         <el-col :span="10">
          <el-form-item>
            <div slot="label">台数（台）<font color="red">*</font></div>
            <el-input type="number" :min="0" onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))" v-model="form.computerNumber" >
            <i slot="suffix" style="font-style:normal;">台</i>
              </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item>
            <div slot="label">金额<font color="red">*</font></div>
            <el-input type="number" :min="0" v-model="form.computerAmount" @click.native="form.computerAmount = inputHandleClick(form.computerAmount)"  @keyup.native="form.computerAmount = oninput(form.computerAmount,2)">
            <i slot="suffix" style="font-style:normal;">万元</i>
              </el-input>
          </el-form-item>
        </el-col>
      </el-row>
       
      <el-descriptions title="纸质图书补充">
         </el-descriptions>
      <el-row :gutter="24">
         <el-col :span="10">
          <el-form-item>
            <div slot="label">册数<font color="red">*</font></div>
            <el-input type="number" :min="0" onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))" v-model="form.bookNumber" >
            <i slot="suffix" style="font-style:normal;">册</i>
              </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item>
            <div slot="label">金额<font color="red">*</font></div>
            <el-input type="number" :min="0" v-model="form.bookAmount" @click.native="form.bookAmount = inputHandleClick(form.bookAmount)" @keyup.native="form.bookAmount = oninput(form.bookAmount,2)">
            <i slot="suffix" style="font-style:normal;">万元</i>
              </el-input>
          </el-form-item>
        </el-col>
         <el-col :span="10">
          <el-form-item>
            <div slot="label">理科实验药品、材料采购<font color="red">*</font></div>
            <el-input type="number" :min="0" v-model="form.drugsAmount" @click.native="form.drugsAmount = inputHandleClick(form.drugsAmount)" @keyup.native="form.drugsAmount = oninput(form.drugsAmount,2)">
            <i slot="suffix" style="font-style:normal;">万元</i>
              </el-input>
          </el-form-item>
        </el-col>       
      </el-row>  
    </el-form>
     <div style="text-align:center; padding-top:10px">
      <el-button type="primary" @click="saveDraft">保存草稿</el-button>
      <el-button type="primary" @click="save">保存</el-button>
      <el-button type="danger" @click="closePage">取消</el-button>
    </div>
  </div>
    </el-tab-pane>
    </el-tabs>
</template>

<script>

import { Message, MessageBox } from 'element-ui'
import { getDictListByCode } from '@/api/dict'
import { getTableInfo,saveEI } from '@/api/taskSchool'


export default {
  name: '',
  inject: ['getListByAppId'],
  props: { // 第二种方式
    proid: {
      type: String,
      required: true
    },
    tablestate: {
      type: String,
      required: true
    }
  },
  data() {
    return {
tbsm:"本表显示当年教育装备投入及基本情况，包含当年教育技术装备购置经费、多媒体更新、计算机更新、纸质图书补充、和理科实验药品材料的投入情况。</br>1.当年教育技术装备购置经费：“总金额”=“财政拨款金额”+“非财政拨款金额”。</br>2.多媒体更新：当年补充、更新多媒体设备的数量和金额。</br>3.计算机更新：当年学校补充更新用于教育教学的计算机的总台数和总金额(不含pad更新数及金额)。</br>4.纸质图书补充：当年学校补充更新纸质图书的册数和金额。</br>5.理科实验药品材料：当年学校用于开设理科实验所需投入材料和药品的金额。",
up1Visible: false,
      up2Visible: false,
      activeName:'second',
      form: {
        userName:'',
        userCompany:''
      },
      xmName: '',
      themeName: '',
      schemaNa: '',
      standard:{
        kxys:1,
        kxstfz:2,
        kxsyfz:3,
        wlys:9,
        wlfz:91,
        hxys:12,
        hxfz:13,
        swys:27,
        swfz:28,
        swtj:29
      },
      multipleSelection: [],
      txData: [],
      types: [],
      options: [{
          value: '1',
          label: '小学'
        }, {
          value: '2',
          label: '初中'
        }, {
          value: '3',
          label: '完中'
        },{
          value: '4',
          label: '高中'
        }, {
          value: '5',
          label: '九年一贯'
        },{
          value: '6',
          label: '十二年一贯'
        }]
    }
  },
  created() {
    this.getInfo()
  },
  methods: {
    inputHandleClick(v){
      
      if(v==''||v==0||v==0.00){
        return ''
      }
      return v
    },

oninput(val,limit = 0) {
            val = val.replace(/[^\d.]/g, ""); //保留数字
            val = val.replace(/^00/, "0."); //开头不能有两个0
            val = val.replace(/^\./g, "0."); //开头为小数点转换为0.
            val = val.replace(/\.{2,}/g, "."); //两个以上的小数点转换成一个
            val = val.replace(".", "$#$").replace(/\./g, "").replace("$#$", "."); //只保留一个小数点
            /^0\d+/.test(val) ? val = val.slice(1) : ''; //两位以上数字开头不能为0
            const str = '^(\\d+)\\.(\\d{' + limit + '}).*$'
            const reg = new RegExp(str)
            if (limit === 0) {
                // 不需要小数点
                val= val.replace(reg, '$1')
            } else {
                // 通过正则保留小数点后指定的位数
                val= val.replace(reg, '$1.$2')
            }
            return val
        },

     getInfo(){
      var listQuery={}
      listQuery.type="9"
      listQuery.id=this.proid
      getTableInfo(listQuery).then(response => {
        this.form=response.data
      })
    },
    saveDraft(){
       const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
           if(!this.form.acquisitionFundsTotalAmount){
         this.form.acquisitionFundsTotalAmount=0
        }
         if(!this.form.acquisitionFundsFinanceAmount){
         this.form.acquisitionFundsFinanceAmount=0
        }
         if(!this.form.acquisitionFundsNoFinanceAmount){
          this.form.acquisitionFundsNoFinanceAmount=0
        }
         if(!this.form.multiMediaNumber){
          this.form.multiMediaNumber=0
        }
         if(!this.form.multiMediaAmount){
         this.form.multiMediaAmount=0
        }
         if(!this.form.computerNumber){
         this.form.computerNumber=0
        }
         if(!this.form.computerAmount){
         this.form.computerAmount=0
        }
         if(!this.form.bookNumber){
         this.form.bookNumber=0
        }
         if(!this.form.bookAmount){
        this.form.bookAmount=0
        }
         if(!this.form.drugsAmount){
         this.form.drugsAmount=0
        }
        this.form.state='0'
       saveEI(this.form).then(response => {
          Message({
            message: '保存成功',
            type: 'success',
            duration: 5 * 1000
          })
          loading.close()
          // 重新加载表格
          this.getInfo()
        }).catch(response => {
          loading.close()
        })
    },
      save(){
       const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
      
         if(!this.form.acquisitionFundsTotalAmount){
         this.form.acquisitionFundsTotalAmount=0
        }
         if(!this.form.acquisitionFundsFinanceAmount){
         this.form.acquisitionFundsFinanceAmount=0
        }
         if(!this.form.acquisitionFundsNoFinanceAmount){
          this.form.acquisitionFundsNoFinanceAmount=0
        }
         if(!this.form.multiMediaNumber){
          this.form.multiMediaNumber=0
        }
         if(!this.form.multiMediaAmount){
         this.form.multiMediaAmount=0
        }
         if(!this.form.computerNumber){
         this.form.computerNumber=0
        }
         if(!this.form.computerAmount){
         this.form.computerAmount=0
        }
         if(!this.form.bookNumber){
         this.form.bookNumber=0
        }
         if(!this.form.bookAmount){
        this.form.bookAmount=0
        }
         if(!this.form.drugsAmount){
         this.form.drugsAmount=0
        }
        
        this.form.state='1'

       saveEI(this.form).then(response => {
          Message({
            message: '保存成功',
            type: 'success',
            duration: 5 * 1000
          })
          loading.close()
          // 重新加载表格
          this.getInfo()
        }).catch(response => {
          loading.close()
        })
       },
    closePage() {
      // 调用全局挂载的方法,关闭当前标签页
      //  this.$store.dispatch('tagsView/delView', this.$route)
      this.$router.push({ path: '/super/dataFilling' })
    }
  }
}
</script>
