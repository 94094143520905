<template>
<el-tabs v-model="activeName" @tab-click="handleClick">
    <el-tab-pane label="填报须知" name="first">
       <el-form ref="ruleForm" :model="form" label-width="140px" label-position="left">
      <el-row :gutter="20">
        <el-col :span="20">
         <p class="text" v-html="this.tbsm" style="font-size: 18px;line-height:40px"></p>
        </el-col>
      </el-row>
    </el-form>
    </el-tab-pane>
    <el-tab-pane label="表2 实验室管理人员统计表" name="second">
  <div style="width:90%;height:auto; margin:0 auto;">
    <el-form ref="ruleForm" :model="form" label-width="140px" label-position="left">
      <!-- <el-row :gutter="22">
         <el-col :span="8">
          <p style="font-size:20px">人数</p>
        </el-col>
        
      </el-row> -->
       <el-descriptions title="人数">
         </el-descriptions>
      <el-row :gutter="22">
        <el-col :span="8">
          <el-form-item>
            <div slot="label">总数<font color="red">*</font></div>
            <el-input v-model="form.total"  type="number"
  onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))" disabled >
            <i slot="suffix" style="font-style:normal;">人</i>
              </el-input>
          </el-form-item>
        </el-col>
         <el-col :span="8">
          <el-form-item>
            <div slot="label">专职<font color="red">*</font></div>
            <el-input v-model="form.specificDutyNumber" disabled type="number"
  onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))" :min="0">
            <i slot="suffix" style="font-style:normal;">人</i>
              </el-input>
          </el-form-item>
        </el-col>
         <el-col :span="8">
          <el-form-item>
            <div slot="label">兼职<font color="red">*</font></div>
            <el-input v-model="form.pluralistNumber"  type="number"
  onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))" :min="0" >
            <i slot="suffix" style="font-style:normal;">人</i>
              </el-input>
          </el-form-item>
        </el-col>
        
      </el-row>
      <el-descriptions title="专职实验室管理员职称结构（人）" >
         </el-descriptions>
         <el-descriptions title="高级">
         </el-descriptions>
      <el-row :gutter="22">
        <el-col :span="8">
          <el-form-item>
            <div slot="label">实验室系列<font color="red">*</font></div>
            <el-input v-model="form.seniorExperiment"  type="number"
  onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))" :min="0" >
            <i slot="suffix" style="font-style:normal;">人</i>
              </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item>
            <div slot="label">其他系列<font color="red">*</font></div>
            <el-input v-model="form.seniorOther"  type="number"
  onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))" :min="0">
            <i slot="suffix" style="font-style:normal;">人</i>
              </el-input>
          </el-form-item>
        </el-col>
        
      </el-row>
       <el-descriptions title="中级">
         </el-descriptions>
     <el-row :gutter="22">
        <el-col :span="8">
          <el-form-item>
            <div slot="label">实验室系列<font color="red">*</font></div>
            <el-input v-model="form.intermediateExperiment"  type="number"
  onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))" :min="0">
            <i slot="suffix" style="font-style:normal;">人</i>
              </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item>
            <div slot="label">其他系列<font color="red">*</font></div>
            <el-input v-model="form.intermediateOther"  type="number"
  onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))" :min="0">
            <i slot="suffix" style="font-style:normal;">人</i>
              </el-input>
          </el-form-item>
        </el-col>
        
      </el-row>
       <el-descriptions title="初级">
         </el-descriptions>
      <el-row :gutter="22">
        <el-col :span="8">
          <el-form-item>
            <div slot="label">实验室系列<font color="red">*</font></div>
            <el-input v-model="form.primaryExoeriment"  type="number"
  onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))" :min="0">
            <i slot="suffix" style="font-style:normal;">人</i>
              </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item>
            <div slot="label">其他系列<font color="red">*</font></div>
            <el-input v-model="form.primaryOther"  type="number"
  onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))" :min="0">
            <i slot="suffix" style="font-style:normal;">人</i>
              </el-input>
          </el-form-item>
        </el-col>
        
      </el-row>
      
      <el-row :gutter="22">
        <el-col :span="8">
          <el-form-item>
            <div slot="label">
              <el-descriptions title="无职称">
         </el-descriptions></div>
            <el-input v-model="form.noTitle"  type="number"
  onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))" :min="0">
            <i slot="suffix" style="font-style:normal;">人</i>
              </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="22">
        <el-col :span="8">
          <el-form-item>
            <div slot="label">  
            <el-descriptions title="年度培训">
         </el-descriptions></div>
            <el-input v-model="form.annualTraining" type="number"
  onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))" :min="0">
            <i slot="suffix" style="font-style:normal;">人次</i>
              </el-input>
          </el-form-item>
        </el-col>
      </el-row>
     
    </el-form>
     <div style="text-align:center; padding-top:10px">
      <el-button type="primary"  @click="saveDraft">保存草稿</el-button>
      <el-button type="primary"  @click="save">保存</el-button>

      <el-button type="danger" @click="closePage">取消</el-button>
    </div>
  </div>
  </el-tab-pane>
</el-tabs>
</template>

<script>

import { Message, MessageBox } from 'element-ui'
import { getDictListByCode } from '@/api/dict'
import { getTableInfo,saveLMP } from '@/api/taskSchool'

export default {
  name: '',
  inject: ['getListByAppId'],
  props: { // 第二种方式
    proid: {
      type: String,
      required: true
    },
    tablestate: {
      type: String,
      required: true
    }
  },
  data() {
    return {
tbsm:"本表显示中小学实验室管理员队伍的基本状况，小学是指从事实验室管理工作的人员；中学是指从事物理、化学、生物实验室管理工作的人员，包含人数、专兼职情况、专职实验室管理员职称结构和年度培训。</br>1.实验室管理员人数：“总数”=“专职+兼职”；“专职”是指专门从事中小学实验教学和实验室管理工作人员的数量。</br>2.专职实验室管理员职称结构（人）：此项只填写专职实验室管理员的职称情况，“专职”人数=“高级”+“中级”+“初级”+“无职称”；</br>（1）“高级”</br>实验系列是指高级实验师，其他系列是指中学高级教师、研究馆员、副研究馆员等；</br>（2）“中级”</br>实验系列是指实验师，其他系列是指中学一级教师、小学高级教师、馆员等；</br>（3）“初级”</br>实验系列是指助理实验师、实验员等，其他系列是指中学二、三级教师，小学一、二、三级教师、助理馆员等；</br>（4）“无职称”</br>尚未取得职称的实验室管理员人数</br>注：“专职实验室管理员职称结构（人）”总数应等于“专职”总数。</br>3.年度培训（人/次）：是指本年度专兼职实验室管理员参加各级教育行政部门、管理部门和学校组织的实验室管理方面的专项培训次数。</br>",
up1Visible: false,
      up2Visible: false,
      form: {
        userName:'',
        userCompany:''
      },
      xmName: '',
      themeName: '',
      schemaNa: '',
      multipleSelection: [],
      txData: [],
      activeName:'second',
      types: [],
      options: [{
          value: '1',
          label: '小学'
        }, {
          value: '2',
          label: '初中'
        }, {
          value: '3',
          label: '完中'
        },{
          value: '4',
          label: '高中'
        }, {
          value: '5',
          label: '九年一贯'
        },{
          value: '6',
          label: '十二年一贯'
        }]
    }
  },
  created() {
    this.getInfo()
  },
  methods: {
     getInfo(){
      var listQuery={}
      listQuery.type="2"
      listQuery.id=this.proid
      getTableInfo(listQuery).then(response => {
        this.form=response.data
      })
    },
    saveDraft(){
       const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        var sum=0
        sum=this.form.seniorExperiment-0
        sum+=this.form.seniorOther-0
        sum+=this.form.intermediateExperiment-0
        sum+=this.form.intermediateOther-0
        sum+=this.form.primaryExoeriment-0
        sum+=this.form.primaryOther-0
        sum+=this.form.noTitle-0
        this.form.specificDutyNumber=sum
         if(!this.form.pluralistNumber){
        this.form.pluralistNumber=0
        }
        if(!this.form.seniorExperiment){
          this.form.seniorExperiment=0
        }
        if(!this.form.seniorOther){
          this.form.seniorOther=0
        }
        if(!this.form.intermediateExperiment){
          this.form.intermediateExperiment=0
        }
      
        if(!this.form.intermediateOther){
          this.form.intermediateOther=0
        }
        if(!this.form.primaryExoeriment){
          this.form.primaryExoeriment=0
        }
        if(!this.form.primaryOther){
          this.form.primaryOther=0
        }
        if(!this.form.noTitle){
        this.form.noTitle=0
        }
        if(!this.form.annualTraining){
          this.form.annualTraining=0
        }
        this.form.state='0'
       saveLMP(this.form).then(response => {
          Message({
            message: '保存成功',
            type: 'success',
            duration: 5 * 1000
          })
          loading.close()
          // 重新加载表格
          this.getInfo()
        }).catch(response => {
          loading.close()
        })
    },
     save(){
       const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        //  if(!this.form.total){
        //    Message({
        //     message: '总数不能为空',
        //     type: 'error',
        //     duration: 5 * 1000
        //   })
        //   loading.close()
        //   return 
        // }
        var sum=0
        sum=this.form.seniorExperiment-0
        sum+=this.form.seniorOther-0
        sum+=this.form.intermediateExperiment-0
        sum+=this.form.intermediateOther-0
        sum+=this.form.primaryExoeriment-0
        sum+=this.form.primaryOther-0
        sum+=this.form.noTitle-0
        this.form.specificDutyNumber=sum
        // if(!this.form.specificDutyNumber){
        //    Message({
        //     message: '专职数量不能为空',
        //     type: 'error',
        //     duration: 5 * 1000
        //   })
        //   loading.close()
        //   return 
        // }
        if(!this.form.pluralistNumber){
        this.form.pluralistNumber=0
        }
        if(!this.form.seniorExperiment){
          this.form.seniorExperiment=0
        }
        if(!this.form.seniorOther){
          this.form.seniorOther=0
        }
        if(!this.form.intermediateExperiment){
          this.form.intermediateExperiment=0
        }
        // if(!this.form.teacherNumber){
        //    Message({
        //     message: '教师数量不能为空',
        //     type: 'error',
        //     duration: 5 * 1000
        //   })
        //   loading.close()
        //   return 
        // }
        if(!this.form.intermediateOther){
          this.form.intermediateOther=0
        }
        if(!this.form.primaryExoeriment){
          this.form.primaryExoeriment=0
        }
        if(!this.form.primaryOther){
          this.form.primaryOther=0
        }
        if(!this.form.noTitle){
        this.form.noTitle=0
        }
        if(!this.form.annualTraining){
          this.form.annualTraining=0
        }
        this.form.state=1
       saveLMP(this.form).then(response => {
          Message({
            message: '保存成功',
            type: 'success',
            duration: 5 * 1000
          })
          loading.close()
          // 重新加载表格
          this.getInfo()
        }).catch(response => {
          loading.close()
        })
    },
    closePage() {
      // 调用全局挂载的方法,关闭当前标签页
      //  this.$store.dispatch('tagsView/delView', this.$route)
      this.$router.push({ path: '/super/dataFilling' })
    }
  }
}
</script>
