<template>
<el-tabs v-model="activeName" @tab-click="handleClick">
    <el-tab-pane label="填报须知" name="first">
       <el-form ref="ruleForm" :model="form" label-width="140px" label-position="left">
      <el-row :gutter="20">
        <el-col :span="20">
         <p class="text" v-html="this.tbsm" style="font-size: 18px;line-height:40px"></p>
        </el-col>
      </el-row>
    </el-form>
    </el-tab-pane>
    <el-tab-pane label="表7 图书馆管理人员" name="second">
  <div style="width:90%;height:auto; margin:0 auto;">
    <el-form ref="ruleForm" :model="form" label-width="120px" label-position="left">
    
     <el-descriptions title="人数（人）">
         </el-descriptions>
      <el-row :gutter="24">       
         <el-col :span="8">
          <el-form-item>
            <div slot="label">总数<font color="red">*</font></div>
            <el-input type="number" onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))" disabled :min="0" v-model="form.personnelTotal"  >
            <i slot="suffix" style="font-style:normal;">人</i>
              </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item>
            <div slot="label">专职<font color="red">*</font></div>
            <el-input type="number" disabled onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))"  :min="0" v-model="form.fullTime" >
            <i slot="suffix" style="font-style:normal;">人</i>
              </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item>
            <div slot="label">兼职<font color="red">*</font></div>
            <el-input type="number" onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))"  :min="0" v-model="form.partTime" >
            <i slot="suffix" style="font-style:normal;">人</i>
              </el-input>
          </el-form-item>
        </el-col>
      </el-row>      

      
       <el-descriptions title="专职图书管理员职称结构（人）">
         </el-descriptions>
       <el-descriptions title="高级">
         </el-descriptions>
      <el-row :gutter="24">
        <el-col :span="10">
          <el-form-item>
            <div slot="label">图书馆系列<font color="red">*</font></div>
            <el-input type="number" onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))"  :min="0" v-model="form.seniorLibrary" >
            <i slot="suffix" style="font-style:normal;">人</i>
              </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item>
            <div slot="label">其他系列<font color="red">*</font></div>
            <el-input type="number" onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))"  :min="0" v-model="form.seniorOther" >
            <i slot="suffix" style="font-style:normal;">人</i>
              </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-descriptions title="中级">
         </el-descriptions>
      <el-row :gutter="24">
         <el-col :span="10">
          <el-form-item>
            <div slot="label">图书馆系列<font color="red">*</font></div>
            <el-input type="number" onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))"  :min="0" v-model="form.intermediateLibrary" >
            <i slot="suffix" style="font-style:normal;">人</i>
              </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item>
            <div slot="label">其他系列<font color="red">*</font></div>
            <el-input type="number" onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))"  :min="0" v-model="form.intermediateOther" >
            <i slot="suffix" style="font-style:normal;">人</i>
              </el-input>
          </el-form-item>
        </el-col>
      </el-row>

         <el-descriptions title="初级">
         </el-descriptions>
      <el-row :gutter="24">
         <el-col :span="10">
          <el-form-item>
            <div slot="label">图书馆系列<font color="red">*</font></div>
            <el-input type="number" onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))"  :min="0" v-model="form.primaryLibrary" >
            <i slot="suffix" style="font-style:normal;">人</i>
              </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item>
            <div slot="label">其他系列<font color="red">*</font></div>
            <el-input type="number" onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))"  :min="0" v-model="form.primaryOther" >
            <i slot="suffix" style="font-style:normal;">人</i>
              </el-input>
          </el-form-item>
        </el-col>
      </el-row>

       <el-row :gutter="24">
        
        <el-col :span="10">
          <el-form-item>
            <div slot="label"> <el-descriptions title="无职称">
         </el-descriptions></div>
            <el-input type="number" onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))"  :min="0" v-model="form.noTitle" >
            <i slot="suffix" style="font-style:normal;">人</i>
              </el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="24">
        
        <el-col :span="10">
          <el-form-item>
            <div slot="label"> <el-descriptions title="图书馆专业毕业人数">
         </el-descriptions></div>
            <el-input type="number" onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))"  :min="0" v-model="form.graduatedLibraryNumber" >
            <i slot="suffix" style="font-style:normal;">人</i>
              </el-input>
          </el-form-item>
        </el-col>
       
      </el-row>

       <el-row :gutter="24">
        
         <el-col :span="10">
          <el-form-item>
            <div slot="label"> <el-descriptions title="年度培训">
         </el-descriptions></div>
            <el-input type="number" onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))"  :min="0" v-model="form.trainNumber" >
            <i slot="suffix" style="font-style:normal;">人次</i>
              </el-input>
          </el-form-item>
        </el-col>
      </el-row>
       
     
  
    </el-form>
     <div style="text-align:center; padding-top:10px">
      <el-button type="primary"  @click="saveDraft">保存草稿</el-button>
      <el-button type="primary"  @click="save">保存</el-button>

      <el-button type="danger" @click="closePage">取消</el-button>
    </div>
  </div>
    </el-tab-pane>
</el-tabs>
</template>

<script>

import { Message, MessageBox } from 'element-ui'
import { getDictListByCode } from '@/api/dict'
import { getTableInfo,saveLA } from '@/api/taskSchool'


export default {
  name: '',
  inject: ['getListByAppId'],
  props: { // 第二种方式
    proid: {
      type: String,
      required: true
    },
    tablestate: {
      type: String,
      required: true
    }
  },
  data() {
    return {
tbsm:"本表包含从事中小学图书馆（室）管理队伍建设的基本状况，包括人数、专职图书管理员职称结构、图书馆专业毕业人数和年度培训。</br>1.图书管理员人数：“总数”=“专职+兼职”；“专职”是指专门从事图书馆（室）管理工作人员的数量。</br>2.专职图书管理员职称结构（人）：此项只填写专职图书管理员的职称情况；“人数”中的“专职”=“高级”+“中级”+“初级”+“无职称”。</br>（1）高级</br>“图书馆系列”即研究馆员、副研究馆员；“其他系列”是指中学高级教师、高级实验师等；</br>（2）中级</br>“图书馆系列”即馆员；“其他系列”是指中学一级教师，小学高级教师、实验师等；</br>（3）初级</br>“图书馆系列”即助理馆员；“其他系列”是指中学二、三级教师，小学一、二、三级教师和助理实验师、实验员等；</br>（4）无职称</br>尚未取得职称的图书管理员人数。</br>注：“专职图书管理员职称结构（人）”总数应等于“专职”总数。</br>3.图书馆专业毕业人数：具有图书馆相关专业学历的图书馆管理工作人员数量。</br>4.年度培训（人/次）：是指本年度专兼职图书管理员参加各级教育行政部门、管理部门和学校组织的图书管理方面的专项培训次数。</br>",
      up1Visible: false,
      up2Visible: false,
      form: {
        userName:'',
        userCompany:''
      },
      xmName: '',
      themeName: '',
      schemaNa: '',
      standard:{
        kxys:1,
        kxstfz:2,
        kxsyfz:3,
        wlys:9,
        wlfz:91,
        hxys:12,
        hxfz:13,
        swys:27,
        swfz:28,
        swtj:29
      },
      multipleSelection: [],
      txData: [],
      activeName:'second',
      types: [],
      options: [{
          value: '1',
          label: '小学'
        }, {
          value: '2',
          label: '初中'
        }, {
          value: '3',
          label: '完中'
        },{
          value: '4',
          label: '高中'
        }, {
          value: '5',
          label: '九年一贯'
        },{
          value: '6',
          label: '十二年一贯'
        }]
    }
  },
  created() {
    this.getInfo()
  },
  methods: {
     getInfo(){
      var listQuery={}
      listQuery.type="7"
      listQuery.id=this.proid
      getTableInfo(listQuery).then(response => {
        this.form=response.data
        
      })
    },
    saveDraft(){
       const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        var sum=0
        sum=this.form.seniorLibrary-0
        sum+=this.form.seniorOther-0
        sum+=this.form.intermediateLibrary-0
        sum+=this.form.intermediateOther-0
        sum+=this.form.primaryLibrary-0
        sum+=this.form.primaryOther-0
        sum+=this.form.noTitle-0
        this.form.fullTime=sum
          if(!this.form.partTime){
          this.form.partTime=0
        }
         if(!this.form.seniorLibrary){
          this.form.seniorLibrary=0
        }
         if(!this.form.seniorOther){
           this.form.seniorOther=0
        }
         if(!this.form.intermediateLibrary){
           this.form.intermediateLibrary=0
        }
         if(!this.form.intermediateOther){
          this.form.intermediateOther=0
        }
         if(!this.form.primaryLibrary){
          this.form.primaryLibrary=0
        }
         if(!this.form.primaryOther){
          this.form.primaryOther=0
        }
         if(!this.form.noTitle){
          this.form.noTitle=0
        }
         if(!this.form.graduatedLibraryNumber){
          this.form.graduatedLibraryNumber=0
        }
         if(!this.form.trainNumber){
           this.form.trainNumber=0
        }
        
         this.form.state='0'
       saveLA(this.form).then(response => {
          Message({
            message: '保存成功',
            type: 'success',
            duration: 5 * 1000
          })
          loading.close()
          // 重新加载表格
          this.getInfo()
        }).catch(response => {
          loading.close()
        })
    },
      save(){
       const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
      
        //  if(!this.form.personnelTotal){
        //    Message({
        //     message: '总数不能为空',
        //     type: 'error',
        //     duration: 5 * 1000
        //   })
        //   loading.close()
        //   return 
        // }
        var sum=0
        sum=this.form.seniorLibrary-0
        sum+=this.form.seniorOther-0
        sum+=this.form.intermediateLibrary-0
        sum+=this.form.intermediateOther-0
        sum+=this.form.primaryLibrary-0
        sum+=this.form.primaryOther-0
        sum+=this.form.noTitle-0
        this.form.fullTime=sum
        //  if(!this.form.fullTime){
        //    Message({
        //     message: '专职不能为空',
        //     type: 'error',
        //     duration: 5 * 1000
        //   })
        //   loading.close()
        //   return 
        // }
         if(!this.form.partTime){
          this.form.partTime=0
        }
         if(!this.form.seniorLibrary){
          this.form.seniorLibrary=0
        }
         if(!this.form.seniorOther){
           this.form.seniorOther=0
        }
         if(!this.form.intermediateLibrary){
           this.form.intermediateLibrary=0
        }
         if(!this.form.intermediateOther){
          this.form.intermediateOther=0
        }
         if(!this.form.primaryLibrary){
          this.form.primaryLibrary=0
        }
         if(!this.form.primaryOther){
          this.form.primaryOther=0
        }
         if(!this.form.noTitle){
          this.form.noTitle=0
        }
         if(!this.form.graduatedLibraryNumber){
          this.form.graduatedLibraryNumber=0
        }
         if(!this.form.trainNumber){
           this.form.trainNumber=0
        }
        

        this.form.state='1'

       saveLA(this.form).then(response => {
          Message({
            message: '保存成功',
            type: 'success',
            duration: 5 * 1000
          })
          loading.close()
          // 重新加载表格
          this.getInfo()
        }).catch(response => {
          loading.close()
        })
       },
    closePage() {
      // 调用全局挂载的方法,关闭当前标签页
      //  this.$store.dispatch('tagsView/delView', this.$route)
      this.$router.push({ path: '/super/dataFilling' })
    }
  }
}
</script>
