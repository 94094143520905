<template>
  <el-tabs v-model="activeName" @tab-click="handleClick">
    <el-tab-pane label="填报须知" name="first">
      <el-form ref="ruleForm" :model="form" label-width="140px" label-position="left">
        <el-row :gutter="20">
          <el-col :span="20">
            <p class="text" style="font-size: 18px;line-height:40px" v-html="this.tbsm" />
          </el-col>
        </el-row>
      </el-form>
    </el-tab-pane>
    <el-tab-pane label="表4 教学仪器情况统计表" name="second">
      <div style="width:90%;height:auto; margin:0 auto;">
        <el-form ref="ruleForm" :model="form" label-width="120px" label-position="left">
       <!-- <el-descriptions title="小学科学"  v-if="this.primary">
         </el-descriptions> -->
          <el-row :gutter="24" v-if="this.primary">
             <el-col :span="4">
         <el-form-item>
            <div slot="label"><p style="font-weight :bold">小学科学</p></div>
          </el-form-item>
         </el-col>
            <el-col :span="10">
              <el-form-item>
                <div slot="label">数量<font color="red">*</font></div>
                <el-input type="number" :min="0"  @blur="getFundTotal"   onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))"  v-model="form.primarySchoolScienceNumber" >
            <i slot="suffix" style="font-style:normal;">台套件</i>
              </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item>
                <div slot="label">金额<font color="red">*</font></div>
                <el-input type="number" :min="0" @blur="getAmpuntTotal"  v-model="form.primarySchoolScienceAmount" @click.native="form.primarySchoolScienceAmount = inputHandleClick(form.primarySchoolScienceAmount)" @keyup.native="form.primarySchoolScienceAmount = oninput(form.primarySchoolScienceAmount,2)">
            <i slot="suffix" style="font-style:normal;">万元</i>
              </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- <el-descriptions title="小学书法" v-if="this.primary">
         </el-descriptions> -->
          <el-row :gutter="24" v-if="this.primary">
             <el-col :span="4">
         <el-form-item>
            <div slot="label"><p style="font-weight :bold">小学书法</p></div>
          </el-form-item>
         </el-col>
            <el-col :span="10">
              <el-form-item>
                <div slot="label">数量<font color="red">*</font></div>
                <el-input type="number" :min="0"  @blur="getFundTotal"   onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))" v-model="form.primarySchoolCalligraphyNumber" >
            <i slot="suffix" style="font-style:normal;">台套件</i>
              </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item>
                <div slot="label">金额<font color="red">*</font></div>
                <el-input type="number" :min="0" @blur="getAmpuntTotal"  v-model="form.primarySchoolCalligraphyAmount" @click.native="form.primarySchoolCalligraphyAmount = inputHandleClick(form.primarySchoolCalligraphyAmount)" @keyup.native="form.primarySchoolCalligraphyAmount = oninput(form.primarySchoolCalligraphyAmount,2)">
            <i slot="suffix" style="font-style:normal;">万元</i>
              </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- <el-descriptions title="中学物理" v-if="this.junior||this.senior">
         </el-descriptions> -->
          <el-row :gutter="24" v-if="this.junior||this.senior">
             <el-col :span="4">
         <el-form-item>
            <div slot="label"><p style="font-weight :bold">中学物理</p></div>
          </el-form-item>
         </el-col>
            <el-col :span="10">
              <el-form-item>
                <div slot="label">数量<font color="red">*</font></div>
                <el-input type="number" :min="0"  @blur="getFundTotal"   onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))" v-model="form.middleSchoolPhysicsNumber" >
            <i slot="suffix" style="font-style:normal;">台套件</i>
              </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item>
                <div slot="label">金额<font color="red">*</font></div>
                <el-input type="number" :min="0" @blur="getAmpuntTotal"  v-model="form.middleSchoolPhysicsAmount" @click.native="form.middleSchoolPhysicsAmount = inputHandleClick(form.middleSchoolPhysicsAmount)"  @keyup.native="form.middleSchoolPhysicsAmount = oninput(form.middleSchoolPhysicsAmount,2)">
            <i slot="suffix" style="font-style:normal;">万元</i>
              </el-input>
              </el-form-item>
            </el-col>

          </el-row>
          <!-- <el-descriptions title="中学化学" v-if="this.junior||this.senior">
         </el-descriptions> -->
          <el-row :gutter="24" v-if="this.junior||this.senior">
              <el-col :span="4">
         <el-form-item>
            <div slot="label"><p style="font-weight :bold">中学化学</p></div>
          </el-form-item>
         </el-col>
            <el-col :span="10">
              <el-form-item>
                <div slot="label">数量<font color="red">*</font></div>
                <el-input type="number" :min="0"  @blur="getFundTotal"   onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))" v-model="form.middleSchoolChemistryNumber" >
            <i slot="suffix" style="font-style:normal;">台套件</i>
              </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item>
                <div slot="label">金额<font color="red">*</font></div>
                <el-input type="number" :min="0" @blur="getAmpuntTotal"  v-model="form.middleSchoolChemistryAmount" @click.native="form.middleSchoolChemistryAmount = inputHandleClick(form.middleSchoolChemistryAmount)" @keyup.native="form.middleSchoolChemistryAmount = oninput(form.middleSchoolChemistryAmount,2)">
            <i slot="suffix" style="font-style:normal;">万元</i>
              </el-input>
              </el-form-item>
            </el-col>

          </el-row>
          <!-- <el-descriptions title="中学生物" v-if="this.junior||this.senior">
         </el-descriptions> -->
          <el-row :gutter="24" v-if="this.junior||this.senior">
             <el-col :span="4">
         <el-form-item>
            <div slot="label"><p style="font-weight :bold">中学生物</p></div>
          </el-form-item>
         </el-col>
            <el-col :span="10">
              <el-form-item>
                <div slot="label">数量<font color="red">*</font></div>
                <el-input type="number" :min="0"  @blur="getFundTotal"   onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))" v-model="form.middleSchoolBiologyNumber" >
            <i slot="suffix" style="font-style:normal;">台套件</i>
              </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item>
                <div slot="label">金额<font color="red">*</font></div>
                <el-input type="number" :min="0" @blur="getAmpuntTotal"  v-model="form.middleSchoolBiologyAmount" @click.native="form.middleSchoolBiologyAmount = inputHandleClick(form.middleSchoolBiologyAmount)" @keyup.native="form.middleSchoolBiologyAmount = oninput(form.middleSchoolBiologyAmount,2)" >
            <i slot="suffix" style="font-style:normal;">万元</i>
              </el-input>
              </el-form-item>
            </el-col>

          </el-row>
          <!-- <el-descriptions title="中学史地" v-if="this.junior||this.senior">
         </el-descriptions> -->
          <el-row :gutter="24" v-if="this.junior||this.senior">
             <el-col :span="4">
         <el-form-item>
            <div slot="label"><p style="font-weight :bold">中学史地</p></div>
          </el-form-item>
         </el-col>
            <el-col :span="10">
              <el-form-item>
                <div slot="label">数量<font color="red">*</font></div>
                <el-input type="number" :min="0"  @blur="getFundTotal"   onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))" v-model="form.middleSchoolHistoryGeographyNumber" >
            <i slot="suffix" style="font-style:normal;">台套件</i>
              </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item>
                <div slot="label">金额<font color="red">*</font></div>
                <el-input type="number" :min="0"  @blur="getAmpuntTotal" v-model="form.middleSchoolHistoryGeographyAmount" @click.native="form.middleSchoolHistoryGeographyAmount = inputHandleClick(form.middleSchoolHistoryGeographyAmount)" @keyup.native="form.middleSchoolHistoryGeographyAmount = oninput(form.middleSchoolHistoryGeographyAmount,2)"  >
            <i slot="suffix" style="font-style:normal;">万元</i>
              </el-input>
              </el-form-item>
            </el-col>

          </el-row>
          <!-- <el-descriptions title="中小学数学" v-if="this.junior||this.senior||this.primary">
         </el-descriptions> -->
          <el-row :gutter="24" v-if="this.junior||this.senior||this.primary">
             <el-col :span="4">
         <el-form-item>
            <div slot="label"><p style="font-weight :bold">中小学数学</p></div>
          </el-form-item>
         </el-col>
            <el-col :span="10">
              <el-form-item>
                <div slot="label">数量<font color="red">*</font></div>
                <el-input type="number" :min="0"  @blur="getFundTotal"   onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))" v-model="form.middleSchoolMathematicsNumber" >
            <i slot="suffix" style="font-style:normal;">台套件</i>
              </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item>
                <div slot="label">金额<font color="red">*</font></div>
                <el-input type="number" :min="0" @blur="getAmpuntTotal"   v-model="form.middleSchoolMathematicsAmount" @click.native="form.middleSchoolMathematicsAmount = inputHandleClick(form.middleSchoolMathematicsAmount)" @keyup.native="form.middleSchoolMathematicsAmount = oninput(form.middleSchoolMathematicsAmount,2)">
            <i slot="suffix" style="font-style:normal;">万元</i>
              </el-input>
              </el-form-item>
            </el-col>

          </el-row>
          <!-- <el-descriptions title="中小学思政">
         </el-descriptions> -->
          <el-row :gutter="24">
              <el-col :span="4">
         <el-form-item>
            <div slot="label"><p style="font-weight :bold">中小学思政</p></div>
          </el-form-item>
         </el-col>
            <el-col :span="10">
              <el-form-item>
                <div slot="label">数量<font color="red">*</font></div>
                <el-input type="number" :min="0"  @blur="getFundTotal"   onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))" v-model="form.middleSchoolPoliticalNumber" >
            <i slot="suffix" style="font-style:normal;">台套件</i>
              </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item>
                <div slot="label">金额<font color="red">*</font></div>
                <el-input type="number" :min="0" @blur="getAmpuntTotal"   v-model="form.middleSchoolPoliticalAmount" @click.native="form.middleSchoolPoliticalAmount = inputHandleClick(form.middleSchoolPoliticalAmount)" @keyup.native="form.middleSchoolPoliticalAmount = oninput(form.middleSchoolPoliticalAmount,2)">
            <i slot="suffix" style="font-style:normal;">万元</i>
              </el-input>
              </el-form-item>
            </el-col>

          </el-row>
          <!-- <el-descriptions title="中小学音乐">
         </el-descriptions> -->
          <el-row :gutter="24">
             <el-col :span="4">
         <el-form-item>
            <div slot="label"><p style="font-weight :bold">中小学音乐</p></div>
          </el-form-item>
         </el-col>
            <el-col :span="10">
              <el-form-item>
                <div slot="label">数量<font color="red">*</font></div>
                <el-input type="number" :min="0"  @blur="getFundTotal"   onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))" v-model="form.middleSchoolMusicNumber" >
            <i slot="suffix" style="font-style:normal;">台套件</i>
              </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item>
                <div slot="label">金额<font color="red">*</font></div>
                <el-input type="number" :min="0" @blur="getAmpuntTotal"  v-model="form.middleSchoolMusicAmount" @click.native="form.middleSchoolMusicAmount = inputHandleClick(form.middleSchoolMusicAmount)" @keyup.native="form.middleSchoolMusicAmount = oninput(form.middleSchoolMusicAmount,2)">
            <i slot="suffix" style="font-style:normal;">万元</i>
              </el-input>
              </el-form-item>
            </el-col>

          </el-row>
          <!-- <el-descriptions title="中小学美术">
         </el-descriptions> -->
          <el-row :gutter="24">
              <el-col :span="4">
         <el-form-item>
            <div slot="label"><p style="font-weight :bold">中小学美术</p></div>
          </el-form-item>
         </el-col>
            <el-col :span="10">
              <el-form-item>
                <div slot="label">数量<font color="red">*</font></div>
                <el-input type="number" :min="0"  @blur="getFundTotal"   onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))" v-model="form.middleSchoolArtsNumber" >
            <i slot="suffix" style="font-style:normal;">台套件</i>
              </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item>
                <div slot="label">金额<font color="red">*</font></div>
                <el-input type="number" :min="0" @blur="getAmpuntTotal"  v-model="form.middleSchoolArtsAmount" @click.native="form.middleSchoolArtsAmount = inputHandleClick(form.middleSchoolArtsAmount)" @keyup.native="form.middleSchoolArtsAmount = oninput(form.middleSchoolArtsAmount,2)">
            <i slot="suffix" style="font-style:normal;">万元</i>
              </el-input>
              </el-form-item>
            </el-col>

          </el-row>
          <!-- <el-descriptions title="中小学体育">
         </el-descriptions> -->
          <el-row :gutter="24">
              <el-col :span="4">
         <el-form-item>
            <div slot="label"><p style="font-weight :bold">中小学体育</p></div>
          </el-form-item>
         </el-col>
            <el-col :span="10">
              <el-form-item>
                <div slot="label">数量<font color="red">*</font></div>
                <el-input type="number" :min="0"  @blur="getFundTotal"   onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))" v-model="form.middleSchoolSportsNumber" >
            <i slot="suffix" style="font-style:normal;">台套件</i>
              </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item>
                <div slot="label">金额<font color="red">*</font></div>
                <el-input type="number" :min="0"  @blur="getAmpuntTotal" v-model="form.middleSchoolSportsAmount"  @click.native="form.middleSchoolSportsAmount = inputHandleClick(form.middleSchoolSportsAmount)" @keyup.native="form.middleSchoolSportsAmount = oninput(form.middleSchoolSportsAmount,2)">
            <i slot="suffix" style="font-style:normal;">万元</i>
              </el-input>
              </el-form-item>
            </el-col>

          </el-row>
          <!-- <el-descriptions title="中小学心理健康">
         </el-descriptions> -->
          <el-row :gutter="24">
             <el-col :span="4">
         <el-form-item>
            <div slot="label"><p style="font-weight :bold">中小学心理健康</p></div>
          </el-form-item>
         </el-col>
            <el-col :span="10">
              <el-form-item>
                <div slot="label">数量<font color="red">*</font></div>
                <el-input type="number" :min="0"  @blur="getFundTotal"   onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))" v-model="form.middleSchoolMentalNumber" >
            <i slot="suffix" style="font-style:normal;">台套件</i>
              </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item>
                <div slot="label">金额<font color="red">*</font></div>
                <el-input type="number" :min="0" @blur="getAmpuntTotal"  v-model="form.middleSchoolMentalAmount" @click.native="form.middleSchoolMentalAmount = inputHandleClick(form.middleSchoolMentalAmount)" @keyup.native="form.middleSchoolMentalAmount = oninput(form.middleSchoolMentalAmount,2)">
            <i slot="suffix" style="font-style:normal;">万元</i>
              </el-input>
              </el-form-item>
            </el-col>

          </el-row>
          
          <el-row :gutter="24">
             <el-col :span="4">
         <el-form-item>
            <div slot="label"><p style="font-weight :bold">中小学劳技/通用技术/综合实践</p></div>
          </el-form-item>
         </el-col>
            <el-col :span="10">
              <el-form-item>
                <div slot="label">数量<font color="red">*</font></div>
                <el-input type="number" :min="0"  @blur="getFundTotal"   onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))" v-model="form.middleSchoolCurrencyNumber" >
            <i slot="suffix" style="font-style:normal;">台套件</i>
              </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item>
                <div slot="label">金额<font color="red">*</font></div>
                <el-input type="number" :min="0"  @blur="getAmpuntTotal" v-model="form.middleSchoolCurrencyAmount" @click.native="form.middleSchoolCurrencyAmount = inputHandleClick(form.middleSchoolCurrencyAmount)" @keyup.native="form.middleSchoolCurrencyAmount = oninput(form.middleSchoolCurrencyAmount,2)">
            <i slot="suffix" style="font-style:normal;">万元</i>
              </el-input>
              </el-form-item>
            </el-col>

          </el-row>
          <!-- <el-descriptions title="中小学多媒体">
         </el-descriptions> -->
          <el-row :gutter="24">
              <el-col :span="4">
         <el-form-item>
            <div slot="label"><p style="font-weight :bold">中小学多媒体</p></div>
          </el-form-item>
         </el-col>
            <el-col :span="10">
              <el-form-item>
                <div slot="label">数量<font color="red">*</font></div>
                <el-input type="number" :min="0"  @blur="getFundTotal"   onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))" v-model="form.middleSchoolMediaNumber" >
            <i slot="suffix" style="font-style:normal;">台套件</i>
              </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item>
                <div slot="label">金额<font color="red">*</font></div>
                <el-input type="number" :min="0" @blur="getAmpuntTotal"  v-model="form.middleSchoolMediaAmount" @click.native="form.middleSchoolMediaAmount = inputHandleClick(form.middleSchoolMediaAmount)" @keyup.native="form.middleSchoolMediaAmount = oninput(form.middleSchoolMediaAmount,2)">
            <i slot="suffix" style="font-style:normal;">万元</i>
              </el-input>
              </el-form-item>
            </el-col>

          </el-row>
          <el-descriptions title="中小学计算机">
         </el-descriptions>
          <el-row :gutter="24">
            <el-col :span="10">
              <el-form-item>
                <div slot="label">计算机数量<font color="red">*</font></div>
                <el-input type="number" :min="0" v-model="form.middleSchoolComputerNumber"  @blur="getFundTotal"   onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))" >
            <i slot="suffix" style="font-style:normal;">台套件</i>
              </el-input>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="7">
              <el-form-item>
                <div slot="label">pad数量<font color="red">*</font></div>
                <el-input type="number" :min="0" v-model="form.middleSchoolPadNumber"  @blur="getFundTotal"   onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))" >
            <i slot="suffix" style="font-style:normal;">台套件</i>
              </el-input>
              </el-form-item>
            </el-col> -->
            <el-col :span="10">
              <el-form-item>
                <div slot="label">计算机金额<font color="red">*</font></div>
                <el-input type="number" :min="0" @blur="getAmpuntTotal" v-model="form.middleSchoolComputerAmount" @click.native="form.middleSchoolComputerAmount = inputHandleClick(form.middleSchoolComputerAmount)" @keyup.native="form.middleSchoolComputerAmount = oninput(form.middleSchoolComputerAmount,2)">
            <i slot="suffix" style="font-style:normal;">万元</i>
              </el-input>
              </el-form-item>
            </el-col>

          </el-row>

          <el-row :gutter="24">
            <el-col :span="10">
              <el-form-item>
                <div slot="label">pad数量<font color="red">*</font></div>
                <el-input type="number" :min="0" v-model="form.middleSchoolPadNumber"  @blur="getFundTotal"   onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))" >
            <i slot="suffix" style="font-style:normal;">台套件</i>
              </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item>
                <div slot="label">pad金额<font color="red">*</font></div>
                <el-input type="number" :min="0" @blur="getAmpuntTotal" v-model="form.middleSchoolPadAmount" @click.native="form.middleSchoolPadAmount = inputHandleClick(form.middleSchoolPadAmount)" @keyup.native="form.middleSchoolPadAmount = oninput(form.middleSchoolPadAmount,2)" >
            <i slot="suffix" style="font-style:normal;">万元</i>
              </el-input>
              </el-form-item>
            </el-col>

          </el-row>
        
           <!-- <el-descriptions title="中小学其他">
         </el-descriptions> -->
          <el-row :gutter="24">
             <el-col :span="4">
         <el-form-item>
            <div slot="label"><p style="font-weight :bold">中小学其他</p></div>
          </el-form-item>
         </el-col>
            <el-col :span="10">
              <el-form-item>
                <div slot="label">数量<font color="red">*</font></div>
                <el-input type="number" :min="0"  @blur="getFundTotal"   onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))" v-model="form.middleSchoolOtherNumber" >
            <i slot="suffix" style="font-style:normal;">台套件</i>
              </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item>
                <div slot="label">金额<font color="red">*</font></div>
                <el-input type="number" :min="0" @blur="getAmpuntTotal"  v-model="form.middleSchoolOtherAmount" @click.native="form.middleSchoolOtherAmount = inputHandleClick(form.middleSchoolOtherAmount)" @keyup.native="form.middleSchoolOtherAmount = oninput(form.middleSchoolOtherAmount,2)">
            <i slot="suffix" style="font-style:normal;">万元</i>
              </el-input>
              </el-form-item>
            </el-col>

          </el-row>


            <el-row :gutter="24">
             <el-col :span="4">
         <el-form-item>
            <div slot="label"><p style="font-weight :bold">合计</p></div>
          </el-form-item>
         </el-col>
            <el-col :span="10">
              <el-form-item>
                <div slot="label">数量合计<font color="red">*</font></div>
                <el-input  type="number" :min="0" disabled v-model="form.fundTotal" >
            <i slot="suffix" style="font-style:normal;">台套件</i>
              </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item>
                <div slot="label">金额合计<font color="red">*</font></div>
                <el-input type="number" :min="0" disabled  v-model="form.ampuntTotal"   @keyup.native="form.ampuntTotal = oninput(form.ampuntTotal,2)">
            <i slot="suffix" style="font-style:normal;">万元</i>
              </el-input>
              </el-form-item>
            </el-col>

          </el-row>

        </el-form>

        <div style="text-align:center; padding-top:10px; padding-bottom: 20px;">
           <!-- <el-button type="primary" @click="saveDraft">保存草稿</el-button>-->
          <el-button type="primary">一键提取</el-button> 
          <el-button type="primary"  @click="saveDraft">保存草稿</el-button>
      <el-button type="primary"  @click="save">保存</el-button>
          <el-button type="danger" @click="closePage">取消</el-button>
        </div>
      </div>
    </el-tab-pane>
  </el-tabs>
</template>

<script>

import { Message, MessageBox } from 'element-ui'
import { getDictListByCode } from '@/api/dict'
import { getTableInfo,saveTI } from '@/api/taskSchool'

export default {
  name: '',
  inject: ['getListByAppId'],
  props: { // 第二种方式
    proid: {
      type: String,
      required: true
    },
    tablestate: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      tbsm: '本表显示学校教学仪器配备的基本情况，包含小学科学、数学、文科、中学物理、中学化学、思政、中学史地、音乐、美术、小学书法、体育、心理健康、多媒体、计算机、劳技/通用/综合实践等各个学科以及表中未体现的相关的教学仪器。</br>1.表中带有“小学”的项目，小学、九年一贯制、十二年一贯制学校必须填写；表中带有“中学”的项目，初中、高中、完整、九年一贯制、十二年一贯制学校必须填写；表中不带“小学”或“中学”的项目，各类学校均应填写。</br>2.数量：单位为“台套件”，以托管帐目上的单位为准。</br>3.金额：单位为“万元”，按四舍五入原则，保留小数点后两位。</br>4.“多媒体”： “数量”是指学校各个教育教学场所使用的多媒体的总套数；“金额”是指学校各个教育教学场所使用的多媒体的总金额，在本表前面各个学科不再重复统计。</br>5.“计算机”：学校用于教学的计算机总台数，其中“计算机”是指台式电脑和笔记本电脑，“pad”是平板。</br>6.合计：“数量”本表中各项“数量”的总和；“金额”本表中各项“金额”的总和。',
      up1Visible: false,
      up2Visible: false,
      form: {
        userName: '',
        userCompany: ''
      },
      xmName: '',
      activeName: 'second',
      themeName: '',
      schemaNa: '',
      primary:false,
      junior:false,
      senior:false,
      multipleSelection: [],
      txData: [],
      types: [],
      options: [{
        value: '1',
        label: '小学'
      }, {
        value: '2',
        label: '初中'
      }, {
        value: '3',
        label: '完中'
      }, {
        value: '4',
        label: '高中'
      }, {
        value: '5',
        label: '九年一贯'
      }, {
        value: '6',
        label: '十二年一贯'
      }]
    }
  },
  created() {
    this.getInfo()
  },
  methods: {
    //金额
    getAmpuntTotal(){
      debugger
      this.form.ampuntTotal = Number(this.form.primarySchoolScienceAmount)+
    Number(this.form.primarySchoolCalligraphyAmount)+
    Number(this.form.middleSchoolPhysicsAmount)+
    Number(this.form.middleSchoolChemistryAmount)+
    Number(this.form.middleSchoolBiologyAmount)+
    Number(this.form.middleSchoolHistoryGeographyAmount)+
    Number(this.form.middleSchoolMathematicsAmount)+
    Number(this.form.middleSchoolPoliticalAmount)+
    Number(this.form.middleSchoolMusicAmount)+
    Number(this.form.middleSchoolArtsAmount)+
    Number(this.form.middleSchoolSportsAmount)+
    Number(this.form.middleSchoolMentalAmount)+
    Number(this.form.middleSchoolCurrencyAmount)+
    Number(this.form.middleSchoolMediaAmount)+
    Number(this.form.middleSchoolComputerAmount)+

    Number(this.form.middleSchoolPadAmount)+
    Number(this.form.middleSchoolOtherAmount)
    this.form.ampuntTotal=this.form.ampuntTotal.toFixed(2)
    },
    //数量
    getFundTotal(){
      this.form.fundTotal = Number(this.form.primarySchoolScienceNumber)+
    Number(this.form.primarySchoolCalligraphyNumber)+
    Number(this.form.middleSchoolPhysicsNumber)+
    Number(this.form.middleSchoolChemistryNumber)+
    Number(this.form.middleSchoolBiologyNumber)+
    Number(this.form.middleSchoolHistoryGeographyNumber)+
    Number(this.form.middleSchoolMathematicsNumber)+
    Number(this.form.middleSchoolPoliticalNumber)+
    Number(this.form.middleSchoolMusicNumber)+
    Number(this.form.middleSchoolArtsNumber)+
    Number(this.form.middleSchoolSportsNumber)+
    Number(this.form.middleSchoolMentalNumber)+
    Number(this.form.middleSchoolCurrencyNumber)+
    Number(this.form.middleSchoolMediaNumber)+
    Number(this.form.middleSchoolComputerNumber)+

    Number(this.form.middleSchoolPadNumber)+
    Number(this.form.middleSchoolOtherNumber)
    },
    inputHandleClick(v){
      
      if(v==''||v==0||v==0.00){
        return ''
      }
      return v
    },
          oninput(val,limit = 0) {
            val = val.replace(/[^\d.]/g, ""); //保留数字
            val = val.replace(/^00/, "0."); //开头不能有两个0
            val = val.replace(/^\./g, "0."); //开头为小数点转换为0.
            val = val.replace(/\.{2,}/g, "."); //两个以上的小数点转换成一个
            val = val.replace(".", "$#$").replace(/\./g, "").replace("$#$", "."); //只保留一个小数点
            /^0\d+/.test(val) ? val = val.slice(1) : ''; //两位以上数字开头不能为0
            const str = '^(\\d+)\\.(\\d{' + limit + '}).*$'
            const reg = new RegExp(str)
            if (limit === 0) {
                // 不需要小数点
                val= val.replace(reg, '$1')
            } else {
                // 通过正则保留小数点后指定的位数
                val= val.replace(reg, '$1.$2')
            }
            return val
        },
     getInfo(){
      var listQuery={}
      listQuery.type="4"
      listQuery.id=this.proid
      getTableInfo(listQuery).then(response => {
        this.form=response.data.teachingInstrument
         var str=response.data.department
        var eduStage=str.split(',')
        for (var i in eduStage) {
        if (eduStage[i] === "0x1") {
          this.primary=true
        }
        if (eduStage[i] === "0x2") {
          this.junior=true
        }
        if (eduStage[i] === "0x4") {
          this.senior=true
        }
        }
      })
    },
    saveDraft(){
       const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })

         //小学
        
         if(!this.form.primarySchoolScienceNumber){
          
           this.form.primarySchoolScienceNumber=0
        }
         if(!this.form.primarySchoolScienceAmount){
           this.form.primarySchoolScienceAmount=0
        }
         if(!this.form.primarySchoolCalligraphyNumber){
           this.form.primarySchoolCalligraphyNumber=0
        }
         if(!this.form.primarySchoolCalligraphyAmount){
          this.form.primarySchoolCalligraphyAmount=0
        }
        
        

      //中小学

       
         if(!this.form.middleSchoolPhysicsNumber){
           this.form.middleSchoolPhysicsNumber=0
        }
         if(!this.form.middleSchoolPhysicsAmount){
           this.form.middleSchoolPhysicsAmount=0
        }
         if(!this.form.middleSchoolChemistryNumber){
          this.form.middleSchoolChemistryNumber=0
        }
         if(!this.form.middleSchoolChemistryAmount){
          this.form.middleSchoolChemistryAmount=0
        }
         if(!this.form.middleSchoolBiologyNumber){
           this.form.middleSchoolBiologyNumber=0
        }
         if(!this.form.middleSchoolBiologyAmount){
           this.form.middleSchoolBiologyAmount=0
        }
         if(!this.form.middleSchoolHistoryGeographyNumber){
           this.form.middleSchoolHistoryGeographyNumber=0
        }
         if(!this.form.middleSchoolHistoryGeographyAmount){
           this.form.middleSchoolHistoryGeographyAmount=0
        }
          
       
          if(!this.form.middleSchoolMathematicsNumber){
          this.form.middleSchoolMathematicsNumber=0
        }
         if(!this.form.middleSchoolMathematicsAmount){
           this.form.middleSchoolMathematicsAmount=0
        }
         if(!this.form.middleSchoolPoliticalNumber){
          this.form.middleSchoolPoliticalNumber=0
        }
         if(!this.form.middleSchoolPoliticalAmount){
          this.form.middleSchoolPoliticalAmount=0
        }
         if(!this.form.middleSchoolMusicNumber){
          this.form.middleSchoolMusicNumber=0
        }
         if(!this.form.middleSchoolMusicAmount){
       this.form.middleSchoolMusicAmount=0
        }
         if(!this.form.middleSchoolArtsNumber){
          this.form.middleSchoolArtsNumber=0
        }
         if(!this.form.middleSchoolArtsAmount){
          this.form.middleSchoolArtsAmount=0
        }
         if(!this.form.middleSchoolSportsNumber){
          this.form.middleSchoolSportsNumber=0
        }
         if(!this.form.middleSchoolSportsAmount){
         this.form.middleSchoolSportsAmount=0
        }

          if(!this.form.middleSchoolMentalNumber){
          this.form.middleSchoolMentalNumber=0
        }
         if(!this.form.middleSchoolMentalAmount){
           this.form.middleSchoolMentalAmount=0
        }
         if(!this.form.middleSchoolMediaNumber){
          this.form.middleSchoolMediaNumber=0
        }
         if(!this.form.middleSchoolMediaAmount){
          this.form.middleSchoolMediaAmount=0
        }
         if(!this.form.middleSchoolComputerNumber){
          this.form.middleSchoolComputerNumber=0
        }
        if(!this.form.middleSchoolPadNumber){
          this.form.middleSchoolPadNumber=0
        }
         if(!this.form.middleSchoolComputerAmount){
         this.form.middleSchoolComputerAmount=0
        }
         if(!this.form.middleSchoolCurrencyNumber){
          this.form.middleSchoolCurrencyNumber=0
        }
         if(!this.form.middleSchoolCurrencyAmount){
          this.form.middleSchoolCurrencyAmount=0
        }

          if(!this.form.middleSchoolOtherNumber){
           this.form.middleSchoolOtherNumber=0
        }
         if(!this.form.middleSchoolOtherAmount){
          this.form.middleSchoolOtherAmount=0
        }
         this.form.state='0'
       saveTI(this.form).then(response => {
          Message({
            message: '保存成功',
            type: 'success',
            duration: 5 * 1000
          })
          loading.close()
          // 重新加载表格
          this.getInfo()
        }).catch(response => {
          loading.close()
        })
    },
    
     save(){
       const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        //小学
        
         if(!this.form.primarySchoolScienceNumber){
          
           this.form.primarySchoolScienceNumber=0
        }
         if(!this.form.primarySchoolScienceAmount){
           this.form.primarySchoolScienceAmount=0
        }
         if(!this.form.primarySchoolCalligraphyNumber){
           this.form.primarySchoolCalligraphyNumber=0
        }
         if(!this.form.primarySchoolCalligraphyAmount){
          this.form.primarySchoolCalligraphyAmount=0
        }
        
        

      //中小学

       
         if(!this.form.middleSchoolPhysicsNumber){
           this.form.middleSchoolPhysicsNumber=0
        }
         if(!this.form.middleSchoolPhysicsAmount){
           this.form.middleSchoolPhysicsAmount=0
        }
         if(!this.form.middleSchoolChemistryNumber){
          this.form.middleSchoolChemistryNumber=0
        }
         if(!this.form.middleSchoolChemistryAmount){
          this.form.middleSchoolChemistryAmount=0
        }
         if(!this.form.middleSchoolBiologyNumber){
           this.form.middleSchoolBiologyNumber=0
        }
         if(!this.form.middleSchoolBiologyAmount){
           this.form.middleSchoolBiologyAmount=0
        }
         if(!this.form.middleSchoolHistoryGeographyNumber){
           this.form.middleSchoolHistoryGeographyNumber=0
        }
         if(!this.form.middleSchoolHistoryGeographyAmount){
           this.form.middleSchoolHistoryGeographyAmount=0
        }
          
       
          if(!this.form.middleSchoolMathematicsNumber){
          this.form.middleSchoolMathematicsNumber=0
        }
         if(!this.form.middleSchoolMathematicsAmount){
           this.form.middleSchoolMathematicsAmount=0
        }
         if(!this.form.middleSchoolPoliticalNumber){
          this.form.middleSchoolPoliticalNumber=0
        }
         if(!this.form.middleSchoolPoliticalAmount){
          this.form.middleSchoolPoliticalAmount=0
        }
         if(!this.form.middleSchoolMusicNumber){
          this.form.middleSchoolMusicNumber=0
        }
         if(!this.form.middleSchoolMusicAmount){
       this.form.middleSchoolMusicAmount=0
        }
         if(!this.form.middleSchoolArtsNumber){
          this.form.middleSchoolArtsNumber=0
        }
         if(!this.form.middleSchoolArtsAmount){
          this.form.middleSchoolArtsAmount=0
        }
         if(!this.form.middleSchoolSportsNumber){
          this.form.middleSchoolSportsNumber=0
        }
         if(!this.form.middleSchoolSportsAmount){
         this.form.middleSchoolSportsAmount=0
        }

          if(!this.form.middleSchoolMentalNumber){
          this.form.middleSchoolMentalNumber=0
        }
         if(!this.form.middleSchoolMentalAmount){
           this.form.middleSchoolMentalAmount=0
        }
         if(!this.form.middleSchoolMediaNumber){
          this.form.middleSchoolMediaNumber=0
        }
         if(!this.form.middleSchoolMediaAmount){
          this.form.middleSchoolMediaAmount=0
        }
         if(!this.form.middleSchoolComputerNumber){
          this.form.middleSchoolComputerNumber=0
        }
        if(!this.form.middleSchoolPadNumber){
          this.form.middleSchoolPadNumber=0
        }
         if(!this.form.middleSchoolComputerAmount){
         this.form.middleSchoolComputerAmount=0
        }
         if(!this.form.middleSchoolCurrencyNumber){
          this.form.middleSchoolCurrencyNumber=0
        }
         if(!this.form.middleSchoolCurrencyAmount){
          this.form.middleSchoolCurrencyAmount=0
        }

          if(!this.form.middleSchoolOtherNumber){
           this.form.middleSchoolOtherNumber=0
        }
         if(!this.form.middleSchoolOtherAmount){
          this.form.middleSchoolOtherAmount=0
        }
       



        this.form.state='1'

       saveTI(this.form).then(response => {
          Message({
            message: '保存成功',
            type: 'success',
            duration: 5 * 1000
          })
          loading.close()
          // 重新加载表格
          this.getInfo()
        }).catch(response => {
          loading.close()
        })
    },
  
    closePage() {
      // 调用全局挂载的方法,关闭当前标签页
      //  this.$store.dispatch('tagsView/delView', this.$route)
      this.$router.push({ path: '/super/dataFilling' })
    }
  }
}
</script>

