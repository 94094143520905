<template>
<el-tabs v-model="activeName" @tab-click="handleClick">
    <el-tab-pane label="填报须知" name="first">
       <el-form ref="ruleForm" :model="form" label-width="140px" label-position="left">
      <el-row :gutter="20">
        <el-col :span="20">
         <p class="text" v-html="this.tbsm1" style="font-size: 18px;line-height:40px"></p>
        
        </el-col>
      </el-row>
    </el-form>
    </el-tab-pane>
    <el-tab-pane label="表1 学校基本情况统计表" name="second">
      <div style="width:90%;height:auto; margin:0 auto;">
    <el-form ref="ruleForm" :model="form" label-width="140px" label-position="left">
       <el-row :gutter="20">
        <el-col :span="16">
          <el-form-item>
            <div slot="label">学校类型<font color="red">*</font></div>
            <el-select v-model="form.schoolType" disabled placeholder="请选择">
              <el-option
               v-for="item in options"
               :key="item.code"
               :label="item.name"
               :value="item.code">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
       <el-row :gutter="20">
        <el-col :span="20">
          <el-form-item>
            <div slot="label">学校名称<font color="red">*</font></div>
            <el-input v-model="form.schoolName" disabled />
           
          </el-form-item>
        </el-col>
        
      </el-row>
      <el-row :gutter="20">
        <el-col :span="20">
          <el-form-item>
            <div slot="label">统一社会信用代码<font color="red">*</font></div>
            <el-input v-model="form.usei" disabled />
          </el-form-item>
        </el-col>
        
      </el-row>
     
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item>
            <div slot="label">班级数量<font color="red">*</font></div>
            <el-input v-model="form.classNumber"  type="number"
  onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))"
  :min="0" > 
              <i slot="suffix" style="font-style:normal;">个</i>
              </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item>
            <div slot="label">学生数量<font color="red">*</font></div>
            <el-input v-model="form.studentsNumber"  type="number"
  onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))" :min="0" > 
              <i slot="suffix" style="font-style:normal;">人</i>
              </el-input>
          </el-form-item>
        </el-col>
       
      </el-row>
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item>
            <div slot="label">教师数量<font color="red">*</font></div>
            <el-input v-model="form.teacherNumber"  type="number"
  onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))" :min="0"> 
              <i slot="suffix" style="font-style:normal;">人</i>
              </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
           <el-form-item>
            <div slot="label">理科实验是否达标<font color="red">*</font></div>
             <el-radio v-model="form.scienceLaboratory" label="1">是</el-radio>
             <el-radio v-model="form.scienceLaboratory" label="2">否</el-radio>
          </el-form-item>
        </el-col>
      </el-row>
     
    
    </el-form>
     <div style="text-align:center; padding-top:10px">
      <el-button type="primary"  @click="saveDraft">保存草稿</el-button>
      <el-button type="primary"  @click="save">保存</el-button>
      <el-button type="danger" @click="closePage">取消</el-button>
    </div>
  </div>
    </el-tab-pane>
   
  </el-tabs>
  
</template>

<script>

import { Message, MessageBox } from 'element-ui'
import { getDictListByCode } from '@/api/dict'
import { getTableInfo,saveBi } from '@/api/taskSchool'

export default {
  name: '',
  inject: ['onloadList'],
  props: { // 第二种方式
    proid: {
      type: String,
      required: true
    },
    tablestate: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      tbsm1:"本表显示实验室及功能教室的建设情况。包含小学科学、中学物理、中学化学、中学生物实验室及其附属用房；思政、中学史地、音乐、美术、小学书法、体育活动、信息科技、专用录播、劳技/通用技术/综合实践等功能教室和其他表中未体现的功能教室及其附属用房。</br>1.表中带有“小学”的项目：小学、九年一贯制、十二年一贯制学校必须填写；表中带有“中学”的项目，初中、高中、完整、九年一贯制、十二年一贯制学校必须填写；表中不带“小学”或“中学”的项目，各类学校均应填写。</br>2.间数：单位为“间”，各类附属用房不在统计范围；农村学校不要以房梁断间进行统计。</br>3.面积：单位“㎡”，按四舍五入原则，只保留整数；各类附属用房均在统计范围。</br>4.“音乐类教室”：即音乐教室、舞蹈教室、钢琴教室、交响乐器室等音乐类教室。</br>5.“美术类教室”：即中国画、油画、版画、水彩画、水粉画等美术类教室。</br>6.“体育活动室”：即在室内的篮球馆、排球馆、羽毛球馆、乒乓球馆、室内游泳馆、室内滑冰场、室内田径馆等的面积，不填报各校露天运动场地及器械室。</br>7.“信息科技”：指传统的计算机教室和云机房，不包含pad教室（pad教室应统计在“其他”内）。</br>注：一室两用的，“间数”累计，面积不重复累计。",
      up1Visible: false,
      up2Visible: false,
      form: {
        userName:'',
        userCompany:''
      },
      xmName: '',
      themeName: '',
      schemaNa: '',
      multipleSelection: [],
      txData: [],
      types: [],
      department:[],
      activeName:'second',
      options: []
    }
  },
  created() {
   this.getInfo()
   this.getDictListByCode("TXPE_OF_CAMOUS");
  },
  methods: {
     //字典
    getDictListByCode(code){
      getDictListByCode(code).then(response=>{
        if(code==='TXPE_OF_CAMOUS'){
          this.options=response.data;
        }
      })
    },
    getInfo(){
      var listQuery={}
      listQuery.type="1"
      listQuery.id=this.proid
      getTableInfo(listQuery).then(response => {
        this.form=response.data.basicInformation
        this.department=response.data.department
        this.form.schoolType=this.department.txpeofcamous
        this.form.schoolName=this.department.name
        this.form.usei=this.department.usei
      })
    },
    saveDraft(){
       const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
         this.form.state='0'
       saveBi(this.form).then(response => {
          Message({
            message: '保存成功',
            type: 'success',
            duration: 5 * 1000
          })
          loading.close()
          // 重新加载表格
          this.loadList()
        }).catch(response => {
          loading.close()
        })
    },

     save(){
       const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        if(!this.form.classNumber){
           Message({
            message: '班级数量不能位空',
            type: 'error',
            duration: 5 * 1000
          })
          loading.close()
          return 
        }
         if(!this.form.studentsNumber){
           Message({
            message: '学生数量不能位空',
            type: 'error',
            duration: 5 * 1000
          })
          loading.close()
          return 
        }
         if(!this.form.teacherNumber){
           Message({
            message: '教师数量不能位空',
            type: 'error',
            duration: 5 * 1000
          })
          loading.close()
          return 
        }
        if(!this.form.scienceLaboratory){
           Message({
            message: '请选择理科实验室是否达标',
            type: 'error',
            duration: 5 * 1000
          })
          loading.close()
          return 
        }
        
        this.form.state="1"
       saveBi(this.form).then(response => {
          Message({
            message: '保存成功',
            type: 'success',
            duration: 5 * 1000
          })
          loading.close()
          // 重新加载表格
          this.loadList()
        }).catch(response => {
          loading.close()
        })
    },
    closePage() {
      // 调用全局挂载的方法,关闭当前标签页
      //  this.$store.dispatch('tagsView/delView', this.$route)
      this.$router.push({ path: '/super/dataFilling' })
    }
  }
}
</script>

