<template>
<el-tabs v-model="activeName" @tab-click="handleClick">
    <el-tab-pane label="填报须知" name="first">
       <el-form ref="ruleForm" :model="form" label-width="140px" label-position="left">
      <el-row :gutter="20">
        <el-col :span="20">
         <p class="text" v-html="this.tbsm" style="font-size: 18px;line-height:40px"></p>
        
        </el-col>
      </el-row>
    </el-form>
    </el-tab-pane>
    <el-tab-pane label="表3 实验室及功能教室使用情况表" name="second">
  <div style="width:90%;height:auto; margin:0 auto;">
    <el-form ref="ruleForm" :model="form" label-width="120px" label-position="left">
      
      <el-row :gutter="24"  v-if="this.primary">
         <el-col :span="4">
         <el-form-item>
            <div slot="label"><p style="font-weight :bold">小学科学</p></div>
          </el-form-item>
         </el-col>
        <el-col :span="10">
          <el-form-item>
            <div slot="label">间数<font color="red">*</font></div>
            <el-input type="number" :min="0"  onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))" v-model="form.primarySchoolScienceNumber" >
              <i slot="suffix" style="font-style:normal;">间</i>
              </el-input>
          </el-form-item>
        </el-col>
         <el-col :span="10">
          <el-form-item>
            <div slot="label">面积<font color="red">*</font></div>
            <el-input   onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))" v-model="form.primarySchoolScienceArea" >
               <i slot="suffix" style="font-style:normal;">㎡</i>
              </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      
      <el-row :gutter="24" v-if="this.primary">
         <el-col :span="4">
         <el-form-item>
            <div slot="label"><p style="font-weight :bold">小学书法</p></div>
          </el-form-item>
         </el-col>
        <el-col :span="10">
          <el-form-item>
            <div slot="label">间数<font color="red">*</font></div>
            <el-input type="number" :min="0"  onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))" v-model="form.primarySchoolCalligraphyNumber" >
               <i slot="suffix" style="font-style:normal;">间</i>
              </el-input>
          </el-form-item>
        </el-col>
         <el-col :span="10">
          <el-form-item>
            <div slot="label">面积<font color="red">*</font></div>
            <el-input type="number" :min="0"  onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))" v-model="form.primarySchoolCalligraphyArea" >
               <i slot="suffix" style="font-style:normal;">㎡</i>
              </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      
      <el-row :gutter="24" v-if="this.junior||this.senior">
        <el-col :span="4">
         <el-form-item>
            <div slot="label"><p style="font-weight :bold">中学物理</p></div>
           
          </el-form-item>
        </el-col>
        
        <el-col :span="10">
          <el-form-item>
            <div slot="label">间数<font color="red">*</font></div>
            <el-input type="number" :min="0"  onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))" v-model="form.middleSchoolPhysicsNumber">
               <i slot="suffix" style="font-style:normal;">间</i>
              </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item>
            <div slot="label">面积<font color="red">*</font></div>
            <el-input type="number" :min="0"  onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))" v-model="form.middleSchoolPhysicsArea">
               <i slot="suffix" style="font-style:normal;">㎡</i>
              </el-input>
          </el-form-item>
        </el-col>
      </el-row>
       <!-- <el-descriptions title="中学化学" v-if="this.junior||this.senior">
         </el-descriptions> -->
       <el-row :gutter="24" v-if="this.junior||this.senior">
         <el-col :span="4">
         <el-form-item>
            <div slot="label"><p style="font-weight :bold">中学化学</p></div>
           
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item>
            <div slot="label">间数<font color="red">*</font></div>
            <el-input type="number" :min="0"  onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))" v-model="form.middleSchoolChemistryNumber">
               <i slot="suffix" style="font-style:normal;">间</i>
              </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item>
            <div slot="label">面积<font color="red">*</font></div>
            <el-input type="number" :min="0"  onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))" v-model="form.middleSchoolChemistryArea">
               <i slot="suffix" style="font-style:normal;">㎡</i>
              </el-input>
          </el-form-item>
        </el-col>
        
      </el-row>
      <!-- <el-descriptions title="中学生物" v-if="this.junior||this.senior">
         </el-descriptions> -->
      <el-row :gutter="24" v-if="this.junior||this.senior">
          <el-col :span="4">
         <el-form-item>
            <div slot="label"><p style="font-weight :bold">中学生物</p></div>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item>
            <div slot="label">间数<font color="red">*</font></div>
            <el-input type="number" :min="0"  onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))" v-model="form.middleSchoolBiologyNumber">
               <i slot="suffix" style="font-style:normal;">间</i>
              </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item>
            <div slot="label">面积<font color="red">*</font></div>
            <el-input type="number" :min="0"  onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))" v-model="form.middleSchoolBiologyArea" >
               <i slot="suffix" style="font-style:normal;">㎡</i>
              </el-input>
          </el-form-item>
        </el-col>
        
      </el-row>
      <!-- <el-descriptions title="中学史地" v-if="this.junior||this.senior">
         </el-descriptions> -->
       <el-row :gutter="24" v-if="this.junior||this.senior">
          <el-col :span="4">
         <el-form-item>
            <div slot="label"><p style="font-weight :bold">中学史地</p></div>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item>
            <div slot="label">间数<font color="red">*</font></div>
            <el-input type="number" :min="0"  onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))" v-model="form.middleSchoolHistoricalGeographyNumber" >
               <i slot="suffix" style="font-style:normal;">间</i>
              </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item>
            <div slot="label">面积<font color="red">*</font></div>
            <el-input type="number" :min="0"  onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))" v-model="form.middleSchoolHistoricalGeographyArea" >
               <i slot="suffix" style="font-style:normal;">㎡</i>
              </el-input>
          </el-form-item>
        </el-col>
        
      </el-row>
      <!-- <el-descriptions title="中小学思政" v-if="this.junior||this.senior||this.primary">
         </el-descriptions> -->
      <el-row :gutter="24" v-if="this.junior||this.senior||this.primary">
          <el-col :span="4">
         <el-form-item>
            <div slot="label"><p style="font-weight :bold">中小学思政</p></div>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item>
            <div slot="label">间数<font color="red">*</font></div>
            <el-input type="number" :min="0"  onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))" v-model="form.middleSchoolPoliticalNumber">
               <i slot="suffix" style="font-style:normal;">间</i>
              </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item>
            <div slot="label">面积<font color="red">*</font></div>
            <el-input type="number" :min="0"  onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))" v-model="form.middleSchoolPoliticalArea" >
               <i slot="suffix" style="font-style:normal;">㎡</i>
              </el-input>
          </el-form-item>
        </el-col>
        
      </el-row>
       <!-- <el-descriptions title="中小学音乐" v-if="this.junior||this.senior||this.primary">
         </el-descriptions> -->
      <el-row :gutter="24" v-if="this.junior||this.senior||this.primary">
          <el-col :span="4">
         <el-form-item>
            <div slot="label"><p style="font-weight :bold">中小学音乐</p></div>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item>
            <div slot="label">间数<font color="red">*</font></div>
            <el-input type="number" :min="0"  onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))" v-model="form.middleSchoolMusicNumber" >
               <i slot="suffix" style="font-style:normal;">间</i>
              </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item>
            <div slot="label">面积<font color="red">*</font></div>
            <el-input type="number" :min="0"  onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))" v-model="form.middleSchoolMusicArea">
               <i slot="suffix" style="font-style:normal;">㎡</i>
              </el-input>
          </el-form-item>
        </el-col>
        
      </el-row>
      <!-- <el-descriptions title="中小学美术" v-if="this.junior||this.senior||this.primary">
         </el-descriptions> -->
         
      <el-row :gutter="22" v-if="this.junior||this.senior||this.primary">
         <el-col :span="4">
         <el-form-item>
            <div slot="label"><p style="font-weight :bold">中小学美术</p></div>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item>
            <div slot="label">间数<font color="red">*</font></div>
            <el-input type="number" :min="0"  onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))" v-model="form.middleSchoolArtsNumber">
               <i slot="suffix" style="font-style:normal;">间</i>
              </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item>
            <div slot="label">面积<font color="red">*</font></div>
            <el-input type="number" :min="0"  onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))" v-model="form.middleSchoolArtsArea">
               <i slot="suffix" style="font-style:normal;">㎡</i>
              </el-input>
          </el-form-item>
        </el-col>
        
      </el-row>
       <!-- <el-descriptions title="中小学体育活动" v-if="this.junior||this.senior||this.primary">
         </el-descriptions> -->
      <el-row :gutter="24" v-if="this.junior||this.senior||this.primary">
         <el-col :span="4">
         <el-form-item>
            <div slot="label"><p style="font-weight :bold">中小学体育活动</p></div>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item>
            <div slot="label">间数<font color="red">*</font></div>
            <el-input type="number" :min="0"  onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))" v-model="form.middleSchoolSportsNumber">
               <i slot="suffix" style="font-style:normal;">间</i>
              </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item>
            <div slot="label">面积<font color="red">*</font></div>
            <el-input type="number" :min="0"  onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))" v-model="form.middleSchoolSportsArea">
               <i slot="suffix" style="font-style:normal;">㎡</i>
              </el-input>
          </el-form-item>
        </el-col>
        
      </el-row>
       <!-- <el-descriptions title="中小学心理健康" v-if="this.junior||this.senior||this.primary">
         </el-descriptions> -->
      <el-row :gutter="24" v-if="this.junior||this.senior||this.primary">
         <el-col :span="4">
         <el-form-item>
            <div slot="label"><p style="font-weight :bold">中小学心理健康</p></div>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item>
            <div slot="label">间数<font color="red">*</font></div>
            <el-input type="number" :min="0"  onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))" v-model="form.middleSchoolMentalNumber">
               <i slot="suffix" style="font-style:normal;">间</i>
              </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item>
            <div slot="label">面积<font color="red">*</font></div>
            <el-input type="number" :min="0"  onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))" v-model="form.middleSchoolMentalArea" >
               <i slot="suffix" style="font-style:normal;">㎡</i>
              </el-input>
          </el-form-item>
        </el-col>
        
      </el-row>
      <!-- <el-descriptions title="中小学信息科技" v-if="this.junior||this.senior||this.primary">
         </el-descriptions> -->
      <el-row :gutter="24" v-if="this.junior||this.senior||this.primary">
         <el-col :span="4">
         <el-form-item>
            <div slot="label"><p style="font-weight :bold">中小学信息科技</p></div>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item>
            <div slot="label">间数<font color="red">*</font></div>
            <el-input type="number" :min="0"  onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))" v-model="form.middleSchoolInformationNumber" >
               <i slot="suffix" style="font-style:normal;">间</i>
              </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item>
            <div slot="label">面积<font color="red">*</font></div>
            <el-input type="number" :min="0"  onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))" v-model="form.middleSchoolInformationArea" >
               <i slot="suffix" style="font-style:normal;">㎡</i>
              </el-input>
          </el-form-item>
        </el-col>
        
      </el-row>
      <!-- <el-descriptions title="中小学专用录播" v-if="this.junior||this.senior||this.primary">
         </el-descriptions> -->
      <el-row :gutter="24" v-if="this.junior||this.senior||this.primary">
        <el-col :span="4">
         <el-form-item>
            <div slot="label"><p style="font-weight :bold">中小学专用录播</p></div>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item>
            <div slot="label">间数<font color="red">*</font></div>
            <el-input type="number" :min="0"  onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))" v-model="form.middleSchoolBroadcastNumber" >
               <i slot="suffix" style="font-style:normal;">间</i>
              </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item>
            <div slot="label">面积<font color="red">*</font></div>
            <el-input type="number" :min="0"  onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))" v-model="form.middleSchoolBroadcastArea" >
               <i slot="suffix" style="font-style:normal;">㎡</i>
              </el-input>
          </el-form-item>
        </el-col>
        
      </el-row>
      <!-- <el-descriptions title="中小学劳技/通用技术/综合实践" v-if="this.junior||this.senior||this.primary">
         </el-descriptions> -->
      <el-row :gutter="24" v-if="this.junior||this.senior||this.primary">
         <el-col :span="4">
         <el-form-item>
            <div slot="label"><p style="font-weight :bold">中小学劳技/通用技术/综合实践</p></div>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item>
            <div slot="label">间数<font color="red">*</font></div>
            <el-input type="number" :min="0"  onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))" v-model="form.middleSchoolTechnologyNumber" >
               <i slot="suffix" style="font-style:normal;">间</i>
              </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item>
            <div slot="label">面积<font color="red">*</font></div>
            <el-input type="number" :min="0"  onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))" v-model="form.middleSchoolTechnologyArea" >
               <i slot="suffix" style="font-style:normal;">㎡</i>
              </el-input>
          </el-form-item>
        </el-col>
        
      </el-row>
         <!-- <el-descriptions title="中小学其他" v-if="this.junior||this.senior||this.primary">
         </el-descriptions> -->
      <el-row :gutter="24" v-if="this.junior||this.senior||this.primary">
        <el-col :span="4">
         <el-form-item>
            <div slot="label"><p style="font-weight :bold">中小学其他</p></div>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item>
            <div slot="label">间数<font color="red">*</font></div>
            <el-input type="number" :min="0"  onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))" v-model="form.middleSchoolOtherNumber" >
               <i slot="suffix" style="font-style:normal;">间</i>
              </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item>
            <div slot="label">面积<font color="red">*</font></div>
            <el-input type="number" :min="0"  onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))" v-model="form.middleSchoolOtherArea" >
               <i slot="suffix" style="font-style:normal;">㎡</i>
              </el-input>
          </el-form-item>
        </el-col>
        
      </el-row>
    
    </el-form>
    
    <div style="text-align:center; padding-top:10px; padding-bottom: 20px;">
      <el-button type="primary"  @click="saveDraft">保存草稿</el-button>
      <el-button type="primary"  @click="save">保存</el-button>
      <el-button type="danger" @click="closePage">取消</el-button>
    </div>
  </div>
  </el-tab-pane>
    </el-tabs>
</template>

<script>

import { Message, MessageBox } from 'element-ui'
import { getDictListByCode } from '@/api/dict'
import { getTableInfo,saveCU } from '@/api/taskSchool'


export default {
  name: '',
  inject: ['getListByAppId'],
  props: { // 第二种方式
    proid: {
      type: String,
      required: true
    },
    tablestate: {
      type: String,
      required: true
    }
  },
  data() {
    return {

      tbsm:"本表显示实验室及功能教室的建设情况。包含小学科学、中学物理、中学化学、中学生物实验室及其附属用房；思政、中学史地、音乐、美术、小学书法、体育活动、信息科技、专用录播、劳技/通用技术/综合实践等功能教室和其他表中未体现的功能教室及其附属用房。</br>1.表中带有“小学”的项目：小学、九年一贯制、十二年一贯制学校必须填写；表中带有“中学”的项目，初中、高中、完整、九年一贯制、十二年一贯制学校必须填写；表中不带“小学”或“中学”的项目，各类学校均应填写。</br>2.间数：单位为“间”，各类附属用房不在统计范围；农村学校不要以房梁断间进行统计。</br>3.面积：单位“㎡”，按四舍五入原则，只保留整数；各类附属用房均在统计范围。</br>4.“音乐类教室”：即音乐教室、舞蹈教室、钢琴教室、交响乐器室等音乐类教室。</br>5.“美术类教室”：即中国画、油画、版画、水彩画、水粉画等美术类教室。</br>6.“体育活动室”：即在室内的篮球馆、排球馆、羽毛球馆、乒乓球馆、室内游泳馆、室内滑冰场、室内田径馆等的面积，不填报各校露天运动场地及器械室。</br>7.“信息科技”：指传统的计算机教室和云机房，不包含pad教室（pad教室应统计在“其他”内）。</br>注：一室两用的，“间数”累计，面积不重复累计。",
      up1Visible: false,
      up2Visible: false,
      form: {
        userName:'',
        userCompany:''
      },
      xmName: '',
      activeName:'second',
      primary:false,
      junior:false,
      senior:false,
      primaryj:false,
      juniorj:false,
      seniorj:false,
      themeName: '',
      schemaNa: '',
      multipleSelection: [],
      txData: [],
      EduStage:[],
      types: [],
      options: [{
          value: '1',
          label: '小学'
        }, {
          value: '2',
          label: '初中'
        }, {
          value: '3',
          label: '完中'
        },{
          value: '4',
          label: '高中'
        }, {
          value: '5',
          label: '九年一贯'
        },{
          value: '6',
          label: '十二年一贯'
        }]
    }
  },
  created() {
    this.getInfo()
  },
  methods: {
      getInfo(){
      var listQuery={}
      listQuery.type="3"
      listQuery.id=this.proid
      getTableInfo(listQuery).then(response => {
        this.form=response.data.classroomUsage
        var str=response.data.department
        var eduStage=str.split(',')
        
        for (var i in eduStage) {
        if (eduStage[i] === "0x1") {
          this.primary=true
          this.primaryj=true
        }
        if (eduStage[i] === "0x2") {
          this.junior=true
          this.juniorj=true
        }
        if (eduStage[i] === "0x4") {
          this.senior=true
          this.seniorj=true
        }
      }
      })
    },
    saveDraft(){
       const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
          if(!this.form.primarySchoolScienceNumber){
          this.form.primarySchoolScienceNumber=0
        }
         if(!this.form.primarySchoolScienceArea){
          this.form.primarySchoolScienceArea=0
        }
         if(!this.form.primarySchoolCalligraphyNumber){
          this.form.primarySchoolCalligraphyNumber=0
           
        }
         if(!this.form.primarySchoolCalligraphyArea){
          this.form.primarySchoolCalligraphyArea=0
           
        }
        
       
         if(!this.form.middleSchoolPhysicsNumber){
           this.form.middleSchoolPhysicsNumber=0
        }
         if(!this.form.middleSchoolPhysicsArea){
         this.form.middleSchoolPhysicsArea=0
        }
         if(!this.form.middleSchoolChemistryNumber){
         this.form.middleSchoolChemistryNumber=0
        }
         if(!this.form.middleSchoolChemistryArea){
          this.form.middleSchoolChemistryArea=0
        }
         if(!this.form.middleSchoolBiologyNumber){
          this.form.middleSchoolBiologyNumber=0 
        }
         if(!this.form.middleSchoolBiologyArea){
         this.form.middleSchoolBiologyArea=0
        }
         if(!this.form.middleSchoolHistoricalGeographyNumber){
          this.form.middleSchoolHistoricalGeographyNumber=0
        }
         if(!this.form.middleSchoolHistoricalGeographyArea){
         this.form.middleSchoolHistoricalGeographyArea=0
        }
          
      

        
         if(!this.form.middleSchoolPoliticalNumber){
          this.form.middleSchoolPoliticalNumber=0
        }
         if(!this.form.middleSchoolPoliticalArea){
          this.form.middleSchoolPoliticalArea=0
        }
         if(!this.form.middleSchoolMusicNumber){
          this.form.middleSchoolMusicNumber=0
        }
         if(!this.form.middleSchoolMusicArea){
          this.form.middleSchoolMusicArea=0
        }
         if(!this.form.middleSchoolArtsNumber){
          this.form.middleSchoolArtsNumber=0
        }
         if(!this.form.middleSchoolArtsArea){
          this.form.middleSchoolArtsArea=0
        }
         if(!this.form.middleSchoolSportsNumber){
          this.form.middleSchoolSportsNumber=0
        }
         if(!this.form.middleSchoolSportsArea){
           this.form.middleSchoolSportsArea=0
        }

          if(!this.form.middleSchoolMentalNumber){
          this.form.middleSchoolMentalNumber=0
        }
         if(!this.form.middleSchoolMentalArea){
          this.form.middleSchoolMentalArea=0
        }
         if(!this.form.middleSchoolInformationNumber){
         this.form.middleSchoolInformationNumber=0
        }
         if(!this.form.middleSchoolInformationArea){
          this.form.middleSchoolInformationArea=0
        }
         if(!this.form.middleSchoolBroadcastNumber){
          this.form.middleSchoolBroadcastNumber=0
        }
         if(!this.form.middleSchoolBroadcastArea){
          this.form.middleSchoolBroadcastArea=0
        }
         if(!this.form.middleSchoolTechnologyNumber){
          this.form.middleSchoolTechnologyNumber=0
        }
         if(!this.form.middleSchoolTechnologyArea){
          this.form.middleSchoolTechnologyArea=0
        }

          if(!this.form.middleSchoolOtherNumber){
          this.form.middleSchoolOtherNumber=0
        }
         if(!this.form.middleSchoolOtherArea){
          this.form.middleSchoolOtherArea=0
        }
        
      this.form.state='0'
       saveCU(this.form).then(response => {
          Message({
            message: '保存成功',
            type: 'success',
            duration: 5 * 1000
          })
          loading.close()
          // 重新加载表格
          this.getInfo()
        }).catch(response => {
          loading.close()
        })
    },

     save(){
      
       const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
       
         if(!this.form.primarySchoolScienceNumber){
          this.form.primarySchoolScienceNumber=0
        }
         if(!this.form.primarySchoolScienceArea){
          this.form.primarySchoolScienceArea=0
        }
         if(!this.form.primarySchoolCalligraphyNumber){
          this.form.primarySchoolCalligraphyNumber=0
           
        }
         if(!this.form.primarySchoolCalligraphyArea){
          this.form.primarySchoolCalligraphyArea=0
           
        }
        
       
         if(!this.form.middleSchoolPhysicsNumber){
           this.form.middleSchoolPhysicsNumber=0
        }
         if(!this.form.middleSchoolPhysicsArea){
         this.form.middleSchoolPhysicsArea=0
        }
         if(!this.form.middleSchoolChemistryNumber){
         this.form.middleSchoolChemistryNumber=0
        }
         if(!this.form.middleSchoolChemistryArea){
          this.form.middleSchoolChemistryArea=0
        }
         if(!this.form.middleSchoolBiologyNumber){
          this.form.middleSchoolBiologyNumber=0 
        }
         if(!this.form.middleSchoolBiologyArea){
         this.form.middleSchoolBiologyArea=0
        }
         if(!this.form.middleSchoolHistoricalGeographyNumber){
          this.form.middleSchoolHistoricalGeographyNumber=0
        }
         if(!this.form.middleSchoolHistoricalGeographyArea){
         this.form.middleSchoolHistoricalGeographyArea=0
        }
          
      

        
         if(!this.form.middleSchoolPoliticalNumber){
          this.form.middleSchoolPoliticalNumber=0
        }
         if(!this.form.middleSchoolPoliticalArea){
          this.form.middleSchoolPoliticalArea=0
        }
         if(!this.form.middleSchoolMusicNumber){
          this.form.middleSchoolMusicNumber=0
        }
         if(!this.form.middleSchoolMusicArea){
          this.form.middleSchoolMusicArea=0
        }
         if(!this.form.middleSchoolArtsNumber){
          this.form.middleSchoolArtsNumber=0
        }
         if(!this.form.middleSchoolArtsArea){
          this.form.middleSchoolArtsArea=0
        }
         if(!this.form.middleSchoolSportsNumber){
          this.form.middleSchoolSportsNumber=0
        }
         if(!this.form.middleSchoolSportsArea){
           this.form.middleSchoolSportsArea=0
        }

          if(!this.form.middleSchoolMentalNumber){
          this.form.middleSchoolMentalNumber=0
        }
         if(!this.form.middleSchoolMentalArea){
          this.form.middleSchoolMentalArea=0
        }
         if(!this.form.middleSchoolInformationNumber){
         this.form.middleSchoolInformationNumber=0
        }
         if(!this.form.middleSchoolInformationArea){
          this.form.middleSchoolInformationArea=0
        }
         if(!this.form.middleSchoolBroadcastNumber){
          this.form.middleSchoolBroadcastNumber=0
        }
         if(!this.form.middleSchoolBroadcastArea){
          this.form.middleSchoolBroadcastArea=0
        }
         if(!this.form.middleSchoolTechnologyNumber){
          this.form.middleSchoolTechnologyNumber=0
        }
         if(!this.form.middleSchoolTechnologyArea){
          this.form.middleSchoolTechnologyArea=0
        }

          if(!this.form.middleSchoolOtherNumber){
          this.form.middleSchoolOtherNumber=0
        }
         if(!this.form.middleSchoolOtherArea){
          this.form.middleSchoolOtherArea=0
        }
        



        this.form.state='1'

       saveCU(this.form).then(response => {
          Message({
            message: '保存成功',
            type: 'success',
            duration: 5 * 1000
          })
          loading.close()
          // 重新加载表格
          this.getInfo()
        }).catch(response => {
          loading.close()
        })
    },
  
    closePage() {
      // 调用全局挂载的方法,关闭当前标签页
      //  this.$store.dispatch('tagsView/delView', this.$route)
      this.$router.push({ path: '/super/dataFilling' })
    }
  }
}
</script>
