<template>
<el-tabs v-model="activeName" @tab-click="handleClick">
    <el-tab-pane label="填报须知" name="first">
       <el-form ref="ruleForm" :model="form" label-width="140px" label-position="left">
      <el-row :gutter="20">
        <el-col :span="20">
         <p class="text" v-html="this.tbsm" style="font-size: 18px;line-height:40px"></p>
        </el-col>
      </el-row>
    </el-form>
    </el-tab-pane>
    <el-tab-pane label="表6 理科实验开出情况" name="second">
  <div style="width:90%;height:auto; margin:0 auto;">
    <el-form ref="ruleForm" :model="form" label-width="140px" label-position="left">
       <!-- <el-descriptions title="小学科学" v-if="this.primary">

         </el-descriptions> -->
       <el-row :gutter="22" style="padding-bottom:20px;" v-if="this.primary">
         <el-col :span="11">
         <el-descriptions title="小学科学应做">
         </el-descriptions>
        </el-col>
        <el-col :span="11">
          <el-descriptions title="小学科学实做">
         </el-descriptions>
        </el-col>

      </el-row>
      <el-row :gutter="22" v-if="this.primary">
        <el-col :span="11">
          <el-form-item>
            <div slot="label">演示<font color="red">*</font></div>
            <span >{{ standard.psScienceDemo }}</span>
          </el-form-item>
        </el-col>
         <el-col :span="11">
          <el-form-item>
            <div slot="label">演示<font color="red">*</font></div>
            <el-input type="number" onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))"  :min="0" v-model="form.psScienceDemo" >
            <i slot="suffix" style="font-style:normal;">个</i>
              </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="22" v-if="this.primary">
        <el-col :span="11">
          <el-form-item>
            <div slot="label">随堂分组<font color="red">*</font></div>
            <span >{{ standard.psScienceClassroomGrouping }}</span>
          </el-form-item>
        </el-col>
         <el-col :span="11">
          <el-form-item>
            <div slot="label">随堂分组<font color="red">*</font></div>
            <el-input type="number" onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))"  :min="0" v-model="form.psScienceClassroomGrouping" >
            <i slot="suffix" style="font-style:normal;">个</i>
              </el-input>
          </el-form-item>
        </el-col>
      </el-row>
       <el-row :gutter="22" v-if="this.primary">
        <el-col :span="11">
          <el-form-item>
            <div slot="label">实验室分组<font color="red">*</font></div>
            <span >{{ standard.psScienceLaboratoryGrouping }}</span>
          </el-form-item>
        </el-col>
         <el-col :span="11">
          <el-form-item>
            <div slot="label">实验室分组<font color="red">*</font></div>
            <el-input type="number" onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))"  :min="0" v-model="form.psScienceLaboratoryGrouping" >
            <i slot="suffix" style="font-style:normal;">个</i>
              </el-input>
          </el-form-item>
        </el-col>
      </el-row>

       <!-- <el-descriptions title="初中物理" v-if="this.junior">
         </el-descriptions> -->
       <el-row :gutter="22" style="padding-bottom:20px;" v-if="this.junior">
         <el-col :span="11">
         <el-descriptions title="初中物理应做">
         </el-descriptions>
        </el-col>
        <el-col :span="11">
          <el-descriptions title="初中物理实做">
         </el-descriptions>
        </el-col>

      </el-row>
      <el-row :gutter="22" v-if="this.junior">
        <el-col :span="11">
          <el-form-item>
            <div slot="label">演示<font color="red">*</font></div>
            <span >{{ standard.msPhysicsDemo }}</span>
          </el-form-item>
        </el-col>
         <el-col :span="11">
          <el-form-item>
            <div slot="label">演示<font color="red">*</font></div>
            <el-input type="number" onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))"  :min="0" v-model="form.msPhysicsDemo" >
            <i slot="suffix" style="font-style:normal;">个</i>
              </el-input>
          </el-form-item>
        </el-col>
      </el-row>
        <el-row :gutter="22" v-if="this.junior">
        <el-col :span="11">
          <el-form-item>
            <div slot="label">分组<font color="red">*</font></div>
            <span >{{ standard.msPhysicsGrouping }}</span>
          </el-form-item>
        </el-col>
         <el-col :span="11">
          <el-form-item>
            <div slot="label">分组<font color="red">*</font></div>
            <el-input type="number" onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))"  :min="0" v-model="form.msPhysicsGrouping" >
            <i slot="suffix" style="font-style:normal;">个</i>
              </el-input>
          </el-form-item>
        </el-col>
      </el-row>

       <!-- <el-descriptions title="初中化学" v-if="this.junior">
         </el-descriptions> -->
       <el-row :gutter="22" style="padding-bottom:20px;" v-if="this.junior">
         <el-col :span="11">
         <el-descriptions title="初中化学应做">
         </el-descriptions>
        </el-col>
        <el-col :span="11">
          <el-descriptions title="初中化学实做">
         </el-descriptions>
        </el-col>

      </el-row>
      <el-row :gutter="22" v-if="this.junior">
        <el-col :span="11">
          <el-form-item>
            <div slot="label">演示<font color="red">*</font></div>
            <span >{{ standard.msChemistryDemo }}</span>
          </el-form-item>
        </el-col>
         <el-col :span="11">
          <el-form-item>
            <div slot="label">演示<font color="red">*</font></div>
            <el-input type="number" onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))"  :min="0" v-model="form.msChemistryDemo" >
            <i slot="suffix" style="font-style:normal;">个</i>
              </el-input>
          </el-form-item>
        </el-col>
      </el-row>
        <el-row :gutter="22" v-if="this.junior">
        <el-col :span="11">
          <el-form-item>
            <div slot="label">分组<font color="red">*</font></div>
            <span >{{ standard.msChemistryGrouping }}</span>
          </el-form-item>
        </el-col>
         <el-col :span="11">
          <el-form-item>
            <div slot="label">分组<font color="red">*</font></div>
            <el-input type="number" onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))"  :min="0" v-model="form.msChemistryGrouping" >
            <i slot="suffix" style="font-style:normal;">个</i>
              </el-input>
          </el-form-item>
        </el-col>
      </el-row>
        <!-- <el-descriptions title="初中生物" v-if="this.junior">
         </el-descriptions> -->
       <el-row :gutter="22" style="padding-bottom:20px;" v-if="this.junior">
         <el-col :span="11">
         <el-descriptions title="初中生物应做">
         </el-descriptions>
        </el-col>
        <el-col :span="11">
          <el-descriptions title="初中生物实做">
         </el-descriptions>
        </el-col>

      </el-row>
      <el-row :gutter="22" v-if="this.junior">
        <el-col :span="11">
          <el-form-item>
            <div slot="label">演示<font color="red">*</font></div>
            <span >{{ standard.msOrganismDemo }}</span>
          </el-form-item>
        </el-col>
         <el-col :span="11">
          <el-form-item>
            <div slot="label">演示<font color="red">*</font></div>
            <el-input type="number" onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))"  :min="0" v-model="form.msOrganismDemo" >
            <i slot="suffix" style="font-style:normal;">个</i>
              </el-input>
          </el-form-item>
        </el-col>
      </el-row>
        <el-row :gutter="22" v-if="this.junior">
        <el-col :span="11">
          <el-form-item>
            <div slot="label">分组<font color="red">*</font></div>
            <span >{{ standard.msOrganismClassroomGrouping }}</span>
          </el-form-item>
        </el-col>
         <el-col :span="11">
          <el-form-item>
            <div slot="label">分组<font color="red">*</font></div>
            <el-input type="number" onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))"  :min="0" v-model="form.msOrganismClassroomGrouping" >
            <i slot="suffix" style="font-style:normal;">个</i>
              </el-input>
          </el-form-item>
        </el-col>
      </el-row>
        <el-row :gutter="22" v-if="this.junior">
        <el-col :span="11">
          <el-form-item>
            <div slot="label">探究<font color="red">*</font></div>
            <span >{{ standard.msOrganismLaboratoryGrouping }}</span>
          </el-form-item>
        </el-col>
         <el-col :span="11">
          <el-form-item>
            <div slot="label">探究<font color="red">*</font></div>
            <el-input type="number" onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))"  :min="0" v-model="form.msOrganismLaboratoryGrouping" >
            <i slot="suffix" style="font-style:normal;">个</i>
              </el-input>
          </el-form-item>
        </el-col>
      </el-row>
 <!-- <el-descriptions title="高中物理" v-if="this.senior">
         </el-descriptions> -->
       <el-row :gutter="22" style="padding-bottom:20px;" v-if="this.senior">
         <el-col :span="11">
         <el-descriptions title="高中物理应做">
         </el-descriptions>
        </el-col>
        <el-col :span="11">
          <el-descriptions title="高中物理实做">
         </el-descriptions>
        </el-col>

      </el-row>
      <el-row :gutter="22" v-if="this.senior">
        <el-col :span="11">
          <el-form-item>
            <div slot="label">演示<font color="red">*</font></div>
            <span >{{ standard.hsPhysicsDemo }}</span>
          </el-form-item>
        </el-col>
         <el-col :span="11">
          <el-form-item>
            <div slot="label">演示<font color="red">*</font></div>
            <el-input type="number" onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))"  :min="0" v-model="form.hsPhysicsDemo" >
            <i slot="suffix" style="font-style:normal;">个</i>
              </el-input>
          </el-form-item>
        </el-col>
      </el-row>
        <el-row :gutter="22" v-if="this.senior">
        <el-col :span="11">
          <el-form-item>
            <div slot="label">分组<font color="red">*</font></div>
            <span >{{ standard.hsPhysicsGrouping }}</span>
          </el-form-item>
        </el-col>
         <el-col :span="11">
          <el-form-item>
            <div slot="label">分组<font color="red">*</font></div>
            <el-input type="number" onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))"  :min="0" v-model="form.hsPhysicsGrouping" >
            <i slot="suffix" style="font-style:normal;">个</i>
              </el-input>
          </el-form-item>
        </el-col>
      </el-row>

       <!-- <el-descriptions title="高中化学" v-if="this.senior">
         </el-descriptions> -->
       <el-row :gutter="22" style="padding-bottom:20px;" v-if="this.senior">
         <el-col :span="11">
         <el-descriptions title="高中化学应做">
         </el-descriptions>
        </el-col>
        <el-col :span="11">
          <el-descriptions title="高中化学实做">
         </el-descriptions>
        </el-col>

      </el-row>
      <el-row :gutter="22" v-if="this.senior">
        <el-col :span="11">
          <el-form-item>
            <div slot="label">演示<font color="red">*</font></div>
            <span >{{ standard.hsChemistryDemo }}</span>
          </el-form-item>
        </el-col>
         <el-col :span="11">
          <el-form-item>
            <div slot="label">演示<font color="red">*</font></div>
            <el-input type="number" onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))"  :min="0" v-model="form.hsChemistryDemo" >
            <i slot="suffix" style="font-style:normal;">个</i>
              </el-input>
          </el-form-item>
        </el-col>
      </el-row>
        <el-row :gutter="22" v-if="this.senior">
        <el-col :span="11">
          <el-form-item>
            <div slot="label">分组<font color="red">*</font></div>
            <span >{{ standard.hsChemistryGrouping }}</span>
          </el-form-item>
        </el-col>
         <el-col :span="11">
          <el-form-item>
            <div slot="label">分组<font color="red">*</font></div>
            <el-input type="number" onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))"  :min="0" v-model="form.hsChemistryGrouping" >
            <i slot="suffix" style="font-style:normal;">个</i>
              </el-input>
          </el-form-item>
        </el-col>
      </el-row>
        <!-- <el-descriptions title="高中生物" v-if="this.senior">
         </el-descriptions> -->
       <el-row :gutter="22" style="padding-bottom:20px;" v-if="this.senior">
         <el-col :span="11">
         <el-descriptions title="高中生物应做">
         </el-descriptions>
        </el-col>
        <el-col :span="11">
          <el-descriptions title="高中生物实做">
         </el-descriptions>
        </el-col>

      </el-row>
      <el-row :gutter="22" v-if="this.senior">
        <el-col :span="11">
          <el-form-item>
            <div slot="label">演示<font color="red">*</font></div>
            <span >{{ standard.hsOrganismDemo }}</span>
          </el-form-item>
        </el-col>
         <el-col :span="11">
          <el-form-item>
            <div slot="label">演示<font color="red">*</font></div>
            <el-input type="number" onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))"  :min="0" v-model="form.hsOrganismDemo" >
            <i slot="suffix" style="font-style:normal;">个</i>
              </el-input>
          </el-form-item>
        </el-col>
      </el-row>
        <el-row :gutter="22" v-if="this.senior">
        <el-col :span="11">
          <el-form-item>
            <div slot="label">分组<font color="red">*</font></div>
            <span >{{ standard.hsOrganismClassroomGrouping }}</span>
          </el-form-item>
        </el-col>
         <el-col :span="11">
          <el-form-item>
            <div slot="label">分组<font color="red">*</font></div>
            <el-input type="number" onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))"  :min="0" v-model="form.hsOrganismClassroomGrouping" >
            <i slot="suffix" style="font-style:normal;">个</i>
              </el-input>
          </el-form-item>
        </el-col>
      </el-row>
        <el-row :gutter="22" v-if="this.senior">
        <el-col :span="11">
          <el-form-item>
            <div slot="label">探究<font color="red">*</font></div>
            <span >{{ standard.hsOrganismLaboratoryGrouping }}</span>
          </el-form-item>
        </el-col>
         <el-col :span="11">
          <el-form-item>
            <div slot="label">探究<font color="red">*</font></div>
            <el-input type="number" onKeypress="return(/^[0-9]*$/.test(String.fromCharCode(event.keyCode)))"  :min="0" v-model="form.hsOrganismLaboratoryGrouping" >
            <i slot="suffix" style="font-style:normal;">个</i>
              </el-input>
          </el-form-item>
        </el-col>
      </el-row>


    </el-form>
     <div style="text-align:center; padding-top:10px">
      <el-button type="primary"  @click="saveDraft">保存草稿</el-button>
      <el-button type="primary"  @click="save">保存</el-button>

      <el-button type="danger" @click="closePage">取消</el-button>
    </div>
  </div>
    </el-tab-pane>
</el-tabs>
</template>

<script>

import { Message, MessageBox } from 'element-ui'
import { getDictListByCode } from '@/api/dict'
import { getTableInfo,saveSEA } from '@/api/taskSchool'


export default {
  name: '',
  props: { // 第二种方式
    proid: {
      type: String,
      required: true
    },
    srTaskSchoolId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      tbsm:"填报说明</br> 本表包含中小学理科实验的开出情况，包括小学科学分组和演示实验；中学物理、化学学科分组和演示实验；中学生物分组、演示和探究实验的开出情况。</br>1.应做：是各学科按照课程标准或所用教材规定的必做实验个数，每年统计前根据当年公布的《实验目录》确定当年各科应做实验数量。</br>2.实做：是各个学科实际开出的实验个数。",
      up1Visible: false,
      up2Visible: false,
      activeName:'second',
      form: {},
      xmName: '',
      themeName: '',
      schemaNa: '',
      standard:{},
      multipleSelection: [],
      txData: [],
      primary:false,
      junior:false,
      senior:false,
      types: [],
      options: [{
          value: '1',
          label: '小学'
        }, {
          value: '2',
          label: '初中'
        }, {
          value: '3',
          label: '完中'
        },{
          value: '4',
          label: '高中'
        }, {
          value: '5',
          label: '九年一贯'
        },{
          value: '6',
          label: '十二年一贯'
        }]
    }
  },
  created() {
    this.getInfo()
  },

  methods: {
    getInfo(){
      var listQuery={}
      listQuery.type="6"
      listQuery.id=this.proid
      listQuery.stsId=this.srTaskSchoolId
      getTableInfo(listQuery).then(response => {
         
        this.standard=response.data.scienceExperimentStandard
        this.form=response.data.teachingEquipment
        var str=response.data.department
        var eduStage=str.split(',')
        for (var i in eduStage) {
        if (eduStage[i] === "0x1") {
          this.primary=true
        }
        if (eduStage[i] === "0x2") {
          this.junior=true
        }
        if (eduStage[i] === "0x4") {
          this.senior=true
        }
        }
      })
    },
    saveDraft(){

       const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
              //primary
         if(!this.form.psScienceDemo){
           this.form.psScienceDemo=0
        }
         if(!this.form.psScienceClassroomGrouping){
          this.form.psScienceClassroomGrouping=0
        }
         if(!this.form.psScienceLaboratoryGrouping){
          this.form.psScienceLaboratoryGrouping=0
        }
      //junior
           if(!this.form.msPhysicsDemo){
          this.form.msPhysicsDemo=0
        }
        if(!this.form.msPhysicsGrouping){
          this.form.msPhysicsGrouping=0
        }
        if(!this.form.msChemistryDemo){
          this.form.msChemistryDemo=0
        }
        if(!this.form.msChemistryGrouping){
           this.form.msChemistryGrouping=0
        }
        if(!this.form.msOrganismDemo){
          this.form.msOrganismDemo=0
        }
        if(!this.form.msOrganismClassroomGrouping){
          this.form.msOrganismClassroomGrouping=0
        }
        if(!this.form.msOrganismLaboratoryGrouping){
          this.form.msOrganismLaboratoryGrouping=0
        }
       //senior
          if(!this.form.hsPhysicsDemo){
          this.form.hsPhysicsDemo=0
        }
       if(!this.form.hsPhysicsGrouping){
           this.form.hsPhysicsGrouping=0
        }
        if(!this.form.hsChemistryDemo){
          this.form.hsChemistryDemo=0
        }
        if(!this.form.hsChemistryGrouping){
           this.form.hsChemistryGrouping=0
        }
        if(!this.form.hsOrganismDemo){
          this.form.hsOrganismDemo=0
        }
        if(!this.form.hsOrganismClassroomGrouping){
           this.form.hsOrganismClassroomGrouping=0
        }
        if(!this.form.hsOrganismLaboratoryGrouping){
          this.form.hsOrganismLaboratoryGrouping=0
        }

     
         this.form.state='0'
       saveSEA(this.form).then(response => {
          Message({
            message: '保存成功',
            type: 'success',
            duration: 5 * 1000
          })
          loading.close()
          // 重新加载表格
          this.getInfo()
        }).catch(response => {
          loading.close()
        })
    },
       save(){
       const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
       //primary
         if(!this.form.psScienceDemo){
           this.form.psScienceDemo=0
        }
         if(!this.form.psScienceClassroomGrouping){
          this.form.psScienceClassroomGrouping=0
        }
         if(!this.form.psScienceLaboratoryGrouping){
          this.form.psScienceLaboratoryGrouping=0
        }
      //junior
           if(!this.form.msPhysicsDemo){
          this.form.msPhysicsDemo=0
        }
        if(!this.form.msPhysicsGrouping){
          this.form.msPhysicsGrouping=0
        }
        if(!this.form.msChemistryDemo){
          this.form.msChemistryDemo=0
        }
        if(!this.form.msChemistryGrouping){
           this.form.msChemistryGrouping=0
        }
        if(!this.form.msOrganismDemo){
          this.form.msOrganismDemo=0
        }
        if(!this.form.msOrganismClassroomGrouping){
          this.form.msOrganismClassroomGrouping=0
        }
        if(!this.form.msOrganismLaboratoryGrouping){
          this.form.msOrganismLaboratoryGrouping=0
        }
       //senior
          if(!this.form.hsPhysicsDemo){
          this.form.hsPhysicsDemo=0
        }
       if(!this.form.hsPhysicsGrouping){
           this.form.hsPhysicsGrouping=0
        }
        if(!this.form.hsChemistryDemo){
          this.form.hsChemistryDemo=0
        }
        if(!this.form.hsChemistryGrouping){
           this.form.hsChemistryGrouping=0
        }
        if(!this.form.hsOrganismDemo){
          this.form.hsOrganismDemo=0
        }
        if(!this.form.hsOrganismClassroomGrouping){
           this.form.hsOrganismClassroomGrouping=0
        }
        if(!this.form.hsOrganismLaboratoryGrouping){
          this.form.hsOrganismLaboratoryGrouping=0
        }

      


        this.form.state='1'

       saveSEA(this.form).then(response => {
          Message({
            message: '保存成功',
            type: 'success',
            duration: 5 * 1000
          })
          loading.close()
          // 重新加载表格
          this.getInfo()
        }).catch(response => {
          loading.close()
        })
       },
    closePage() {
      // 调用全局挂载的方法,关闭当前标签页
      //  this.$store.dispatch('tagsView/delView', this.$route)
      this.$router.push({ path: '/super/dataFilling' })
    }
  }
}
</script>
