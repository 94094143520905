<template>
  <div style="width: 100%;height: 100%;margin: 0px auto;overflow: hidden;">
    <div class="app-container">
      <el-aside style="padding-right: 20px; padding-left: 20px; padding-top: 10px;float:left;width:20%;height: 100%;background-color: #fff;">
        <div class="filter-container rz">
          <el-input v-model="filterText" style="width:100%" placeholder="关键字过滤" clearable />
        </div>
        <div style="height: 80%;">
          <el-tree
            ref="tree"
            v-loading="treeLoading"
            style="height:100%"
            :data="treeList"
            class="filter-tree"
            highlight-current
            node-key="id"
            :expand-on-click-node="false"
            :props="defaultProps"
            :filter-node-method="filterNode"
            accordion
            default-expand-all
            @node-click="handleNodeClick"
          />
        </div>
      </el-aside>

      <el-aside v-if="asideTable" style="padding-right: 20px; padding-left: 20px; padding-top: 10px;float:left;width:80%; height:100%;background-color: #fff;">
        <div id="app-contain">
          <div id="filter-container" class="filter-container">

            <el-input v-model="listQuery.name" placeholder="名称" style="width: 200px;" class="filter-item" clearable />

            <el-button class="filter-item" type="primary" icon="el-icon-search" @click="loadList">
              查询
            </el-button>
            
            <el-button style="float: right" class="filter-item" type="danger" icon="el-icon-error" @click="closePage">
              退出
            </el-button>
            <el-button style="float: right" class="filter-item" type="primary" icon="el-icon-success" @click="submitReport">
              提交
            </el-button>
           
             <!-- <el-button style="float: right" class="filter-item" type="danger" icon="el-icon-delete" @click="closePage">
              清空
            </el-button> -->
          </div>
          <el-table
            v-loading="listLoading"
            :data="tablelist"
            :height="tableHeight3"
            border
            fit
            highlight-current-row
            style="width: 100%;overflow:auto"
          >
            <el-table-column label="序号" type="index" align="center" width="50x" />
            <el-table-column label="名称" header-align="center" align="left" prop="srTitle" min-width="250" />
            <!-- <el-table-column label="开始时间" header-align="center" align="right" prop="beginDate" min-width="200" />
        <el-table-column label="截止时间" header-align="center" align="right" prop="endDate" min-width="200" />
        <el-table-column label="填报状态" header-align="center" align="left" prop="state" min-width="150" /> -->
            <el-table-column label="填报状态" header-align="center" align="left" width="150">
              <template slot-scope="scope">
                <span>{{ getDicName(scope.row.srProjectState,'YW_TASK_SCHOOL_STATE') }}</span>
              </template>
            </el-table-column>
            <el-table-column label="创建时间" header-align="center" align="right" prop="srCreateTime" width="150" show-overflow-tooltip />
            <el-table-column label="填报人" header-align="center" align="left" prop="createUserName" min-width="150" />
            <el-table-column label="末次填报时间" header-align="center" align="right" prop="srModifyTime" width="150" show-overflow-tooltip />
            <el-table-column label="提交时间" header-align="center" align="right" prop="srSubmitTime" width="150" show-overflow-tooltip />
             <el-table-column fixed="right" label="操作"  width="100"  header-align="center"  align="left" >  
            <template slot-scope="scope">
            <span>
            
             <el-button  type="primary" size="small"  v-if="scope.row.srInherit!=='2'" class="filter-item" @click="addReport(scope.row)">
              继承
            </el-button>
          </span>
        </template>
      </el-table-column>
          </el-table>
          <!-- <pagination id="pagination" v-show="total>0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="loadList" /> -->
        </div>
      </el-aside>
      <el-aside v-if="dialogVisibleBI" style="padding-right: 20px; padding-left: 20px; padding-top: 10px;float:left;width:80%; height: 100%;background-color: #fff;">
        <basic-information v-if="dialogVisibleBI" ref="basicInformation" :proid="code" :tablestate="tableState" :visible.sync="dialogVisibleBI" />
      </el-aside>
      <el-aside v-if="dialogVisibleLMP" style="padding-right: 20px; padding-left: 20px; padding-top: 10px;float:left;width:80%; height: 100%;background-color: #fff;">
        <laboratory-management-personnel v-if="dialogVisibleLMP" ref="laboratorymanagementpersonnel" :proid="code" :tablestate="tableState" :visible.sync="dialogVisibleLMP" />
      </el-aside>
      <el-aside v-if="dialogVisibleCU" style="padding-right: 20px; padding-left: 20px; padding-top: 10px;float:left;width:80%; height: 100%;background-color: #fff;">
        <classroom-usage v-if="dialogVisibleCU" ref="classroomusage" :proid="code" :tablestate="tableState" :visible.sync="dialogVisibleCU" />
      </el-aside>
      <el-aside v-if="dialogVisibleTI" style="padding-right: 20px; padding-left: 20px; padding-top: 10px;float:left;width:80%; height: 100%;background-color: #fff;">
        <teaching-instrument v-if="dialogVisibleTI" ref="teachinginstrument" :proid="code" :tablestate="tableState" :visible.sync="dialogVisibleTI" />
      </el-aside>
      <el-aside v-if="dialogVisibleTE" style="padding-right: 20px; padding-left: 20px; padding-top: 10px;float:left;width:80%; height: 100%;background-color: #fff;">
        <teaching-equipment v-if="dialogVisibleTE" ref="teachingequipment" :proid="code" :tablestate="tableState" :visible.sync="dialogVisibleTE" />
      </el-aside>
      <el-aside v-if="dialogVisibleSEA" style="padding-right: 20px; padding-left: 20px; padding-top: 10px;float:left;width:80%; height: 100%;background-color: #fff;">
        <science-experiment-actual v-if="dialogVisibleSEA" ref="scienceexperimentactual" :proid="code"  :srTaskSchoolId="srTaskSchoolId" :visible.sync="dialogVisibleSEA" />
      </el-aside>
      <el-aside v-if="dialogVisibleLA" style="padding-right: 20px; padding-left: 20px; padding-top: 10px;float:left;width:80%; height: 100%;background-color: #fff;">
        <library-administrators v-if="dialogVisibleLA" ref="libraryadministrators" :proid="code" :tablestate="tableState" :visible.sync="dialogVisibleLA" />
      </el-aside>
      <el-aside v-if="dialogVisibleLS" style="padding-right: 20px; padding-left: 20px; padding-top: 10px;float:left;width:80%; height: 100%;background-color: #fff;">
        <library-situation v-if="dialogVisibleLS" ref="libraryadministrators" :proid="code" :tablestate="tableState" :visible.sync="dialogVisibleLS" />
      </el-aside>
      <el-aside v-if="dialogVisibleEI" style="padding-right: 20px; padding-left: 20px; padding-top: 10px;float:left;width:80%; height: 100%;background-color: #fff;">
        <educational-investment v-if="dialogVisibleEI" ref="educationalinvestment" :proid="code" :tablestate="tableState" :visible.sync="dialogVisibleEI" />
      </el-aside>
    </div>
  </div>
</template>

<script>
// import { page, submitTest, release, revoke ,getDeployTreeList} from '@/api/api-deploy'
import { getDictListByCode } from '@/api/dict'
import { addTaskSchool,taskSchoolTableList ,submitReport,inherit} from '@/api/taskSchool'
import Pagination from '@/components/Pagination'
import { tableHeight3 } from '@/utils/tableHeight'
import { Message, MessageBox } from 'element-ui'

import BasicInformation from '../dataFilling/basicInformation.vue' // eslint-disable-line no-unused-vars
import LaboratoryManagementPersonnel from '../dataFilling/LaboratoryManagementPersonnel.vue' // eslint-disable-line no-unused-vars
import ClassroomUsage from '../dataFilling/ClassroomUsage.vue' // eslint-disable-line no-unused-vars
import TeachingInstrument from '../dataFilling/TeachingInstrument.vue' // eslint-disable-line no-unused-vars
import TeachingEquipment from '../dataFilling/TeachingEquipment.vue'
import ScienceExperimentActual from '../dataFilling/ScienceExperimentActual.vue'
import LibraryAdministrators from '../dataFilling/LibraryAdministrators.vue'
import LibrarySituation from '../dataFilling/LibrarySituation.vue'
import EducationalInvestment from '../dataFilling/EducationalInvestment.vue'

export default {
  components: { EducationalInvestment, LibrarySituation, BasicInformation, LibraryAdministrators, LaboratoryManagementPersonnel, ClassroomUsage, TeachingInstrument, TeachingEquipment, ScienceExperimentActual },
  mixins: [tableHeight3],
  data() {
    return {
      filterText: null,
      tableState:0,
      list: null,
      tablelist: null,
      total: 0,
      listLoading: true,
      treeLoading: false,
      // 任务页面
      asideAssignment: true,
      // 表单页面
      asideTable: true,
      // 1
      dialogVisibleBI: false,
      // 2
      dialogVisibleLMP: false,
      // 3
      dialogVisibleCU: false,
      // 4
      dialogVisibleTI: false,
      // 5
      dialogVisibleTE: false,
      // 6
      dialogVisibleSEA: false,
      // 7
      dialogVisibleLA: false,
      // 8
      dialogVisibleLS: false,
      // 9
      dialogVisibleEI: false,
      srTaskSchoolId:'',

      listQuery: {
        page: 1,
        limit: 20,
        name: null,
        type: null,
        itemId: this.$getCookie('projectId')
      },

      logQuery: {
        limit: 10,
        page: 1,
        id: null
      },
      task:{},
      taskSchool:{},
      apiTypes: [],
      treeList: [],
      defaultProps: {
        children: 'children',
        label: 'srTitle'
      },
      tabheight: 0,
      logList: [],
      apiDeployStates: [],
      dialogVisibleAdd: false,
      titel: '',
      form: {},
      rules: {}
    }
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val)
    }
  },
  created() {
    this.loadList()
   // this.getTableTreeList()
    this.getDictList('YW_TASK_SCHOOL_STATE')
  },
  methods: {
    closePage() {
      // 调用全局挂载的方法,关闭当前标签页
      //  this.$store.dispatch('tagsView/delView', this.$route)
      this.$router.push({ path: '/super/dataFilling' })
    },
    getDictList(code) {
      getDictListByCode(code).then(response => {
        if (code === 'YW_TASK_SCHOOL_STATE') {
          this.apiDeployStates = response.data
        }
      })
    },
    getDicName(code, flag) {
      var dict = []
      if (flag === 'YW_TASK_SCHOOL_STATE') {
        dict = this.apiDeployStates
      }
      for (var i in dict) {
        if (dict[i].code === code) {
          return dict[i].name
        }
      }
    },
   
    // //节点过滤
    handleNodeClick(data) {
      if (data.level === '1') {
        this.asideTable = true
        this.loadList()
      } else{
        this.asideTable = false

        this.dialogVisibleBI = false
        this.dialogVisibleLMP = false
        this.dialogVisibleCU = false
        this.dialogVisibleTI = false
        this.dialogVisibleTE = false
        this.dialogVisibleSEA = false
        this.dialogVisibleLA = false
        this.dialogVisibleLS = false
        this.dialogVisibleEI = false
        
        if(data.srType === '1') {
          this.code=data.srTableId
          this.tableState=data.srProjectState
          this.dialogVisibleBI = true
        } else if(data.srType === '2') {
          this.tableState=data.srProjectState
          this.code=data.srTableId
          this.dialogVisibleLMP = true
        } else if(data.srType === '3') {
          this.tableState=data.srProjectState
          this.code=data.srTableId
        this.dialogVisibleCU = true
        } else if(data.srType === '4') {
          this.tableState=data.srProjectState
          this.code=data.srTableId
        this.dialogVisibleTI = true
        } else if(data.srType === '5') {
          this.tableState=data.srProjectState
          this.code=data.srTableId
        this.dialogVisibleTE = true
        } else if(data.srType === '6') {
          this.tableState=data.srProjectState
          this.code=data.srTableId
          this.srTaskSchoolId=data.srTaskSchoolId
          
        this.dialogVisibleSEA = true
        } else if(data.srType === '7') {
          this.tableState=data.srProjectState
          this.code=data.srTableId
        this.dialogVisibleLA = true
        } else if(data.srType === '8') {
          this.tableState=data.srProjectState
          this.code=data.srTableId
        this.dialogVisibleLS = true
        } else if(data.srType === '9') {
          this.tableState=data.srProjectState
          this.code=data.srTableId
        this.dialogVisibleEI = true          
        }
      }
    },
    // //节点过滤
    filterNode(value, data) {
      
      if (!value) return true
      
      return data.srTitle.indexOf(value) !== -1
    },
    

    loadList() {
      this.listLoading = true
      this.task=this.$route.query.task
       
      //情况1：传的值stsId为空，说明学校未填报,未填报在学校任务表+1
      if(this.task.id==null){
          this.$router.push({ path: '/super/dataFilling' })
      }else if(this.task.stsId==null&&this.listQuery.id==null&&this.listQuery.addState==null){
         const loading = this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          })
          loading.close()
        this.addTaskSchool(this.task)
        
      
      }else if(this.task.stsId!=null){
        this.listQuery.stsId=this.task.stsId
        this.getList()
      }else if(this.listQuery.id!=null){
        
        this.getList()
      }else if(this.listQuery.addState!=null){
        this.getList()
      }
      //情况2：传的值有stsId，说明学校已点击填报,不做操作

      //获取列表及左侧树
      // taskSchoolPage(this.listQuery).then(response => {
      //   this.listLoading = false
      //   this.tablelist = response.data.records
      //   this.total = parseInt(response.data.total)
      // }).catch(response => {
      //   this.listLoading = false
      // })
      
      // 任务列表
      this.list = [
        {
          'id': '1074999715231170560',
          'name': '22年教育装备填报',
          'beginDate': '2023-02-14 10:25:05',
          'endDate': '2023-02-14 10:25:05',
          'createTimeTi': '2023-02-14 10:25:05',
          'createUserId': '13',
          'createUserName': '用户1',
          'state': '1'

        }
      ]
      this.listLoading = false
      this.total = parseInt(1)
    },
    getList(){
       
      taskSchoolTableList(this.listQuery).then(response => {
        
        this.listLoading = false
        this.tablelist = response.data.tablelist.records
        this.total = parseInt(response.data.tablelist.total)
        
        this.treeList= [response.data.treeList]
        
      //   this.treeList={
      //   name:"2112年教育装备填报",level:1,children:[
      //     {name:"表1 学校基本情况统计表",level:2,type:1},
      //     {name:"表2 实验室管理人员情况统计表",level:2,type:2},
      //     {name:"表3 实验室及功能教室用房情况统计表",level:2,type:3},
      //     {name:"表4 教学仪器情况统计表",level:2,type:4},
      //     {name:"表5 教学设备情况统计表",level:2,type:5},
      //     {name:"表6 理科实验开出情况统计表",level:2,type:6},
      //     {name:"表7 图书管理人员情况统计表",level:2,type:7},
      //     {name:"表8 图书馆（室）情况统计表",level:2,type:8},
      //     {name:"表9 当年教育技术装备投入情况统计表",level:2,type:9}
         
      //   ]
      // }

        this.total = parseInt(this.tablelist.length)
      }).catch(response => {
        this.listLoading = false
      })
    },
    addTaskSchool(task) {
      
      addTaskSchool(task).then(response => {
         
        if(response.data.tablelist!=null){
         
        this.tablelist=response.data.tablelist.records
        this.total = parseInt(response.data.tablelist.total)
        this.listQuery.stsId=response.data.treeList.id
        this.treeList=[response.data.treeList]
        this.listQuery.addState=1
        this.getList()
        }else{
           this.$router.push({ path: '/super/dataFilling' })
        }
        
      })
    },
    handleEdit(row) {
      if (row.type === 1) {
        this.dialogVisibleBI = true
        this.$nextTick(() => {
          this.$refs.basicInformation
        })
      } else if (row.type === 2) {
        this.dialogVisibleLMP = true
        this.$nextTick(() => {
          this.$refs.laboratorymanagementpersonnel
        })
      } else if (row.type === 3) {
        this.dialogVisibleCU = true
        this.$nextTick(() => {
          this.$refs.classroomusage
        })
      } else if (row.type === 4) {
        this.dialogVisibleTI = true
        this.$nextTick(() => {
          this.$refs.teachinginstrument
        })
      } else if (row.type === 5) {
        this.dialogVisibleTE = true
        this.$nextTick(() => {
          this.$refs.teachingequipment
        })
      } else if (row.type === 6) {
        this.dialogVisibleSEA = true
        this.$nextTick(() => {
          this.$refs.scienceexperimentactual
        })
      } else if (row.type === 7) {
        this.dialogVisibleLA = true
        this.$nextTick(() => {
          this.$refs.libraryadministrators
        })
      } else if (row.type === 8) {
        this.dialogVisibleLS = true
        this.$nextTick(() => {
          this.$refs.librarysituation
        })
      } else if (row.type === 9) {
        this.dialogVisibleEI = true
        this.$nextTick(() => {
          this.$refs.educationalinvestment
        })
      }
    },
    // handleSubmitTest(row) {
    //   if (row.state !== '1' && row.state !== '5') {
    //     this.$router.push({ path: './arrange/test', query: { id: row.id }})
    //   } else {
    //     MessageBox.confirm('确定提交测试吗', '确定', {
    //       confirmButtonText: '确定',
    //       cancelButtonText: '取消',
    //       cancelButtonClass: 'qx',
    //       type: 'warning'
    //     }).then(() => {
    //       const loading = this.$loading({
    //         lock: true,
    //         text: 'Loading',
    //         spinner: 'el-icon-loading',
    //         background: 'rgba(0, 0, 0, 0.7)'
    //       })

    //       submitTest(row.id).then(response => {
    //         loading.close()
    //         this.$router.push({ path: './arrange/test', query: { id: row.id }})
    //       }).catch(function() {
    //         loading.close()
    //       })
    //     })
    //   }
    // },
    addReport(row){
       
      var query={}
      query.id=row.srId
      query.type=row.srType
      inherit(query).then(response =>{
        this.getList()
      })
    },
    submitReport() {
      //this.listLoading = true
      this.task=this.$route.query.task
      this.task.stYear=null
      if(this.listQuery.addState!=null){
        this.task.stsId=this.listQuery.stsId
      }
      //情况1：传的值stsId为空，说明学校未填报,未填报在学校任务表+1
      if(this.task.id==null){
          this.$router.push({ path: '/super/dataFilling' })
      }else if(this.task.stsId){
        MessageBox.confirm('确定提交吗', '确定', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        cancelButtonClass: 'qx',
        type: 'warning'
      }).then(() => {
        this.listLoading = true
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        this.listQuery.stsId=this.task.stsId
         this.task.state=2
         
         submitReport(this.task).then(response => {
          Message({
            message: '提交成功',
            type: 'success',
            duration: 5 * 1000
          })
          this.$router.push({ path: '/super/dataFilling' })
          loading.close()
          // 重新加载表格
          this.getList()
        }).catch(response => {
          loading.close()
        })
        loading.close()
       
        this.getList()
         })
      
      }else if(this.task.stsId!=null){
        this.listQuery.stsId=this.task.stsId
        this.getList()
        this.listLoading = false
      }else if(this.listQuery.id!=null){
        
        this.getList()
        this.listLoading = false
      }
    }
    // handleRelease(row) {
    //   MessageBox.confirm('确定发布吗', '确定', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     cancelButtonClass: 'qx',
    //     type: 'warning'
    //   }).then(() => {
    //     const loading = this.$loading({
    //       lock: true,
    //       text: 'Loading',
    //       spinner: 'el-icon-loading',
    //       background: 'rgba(0, 0, 0, 0.7)'
    //     })

    //     release(row.id).then(response => {
    //       Message({
    //         message: '操作成功',
    //         type: 'success',
    //         duration: 5 * 1000
    //       })
    //       loading.close()
    //       this.handleFilter()
    //     }).catch(function() {
    //       loading.close()
    //     })
    //   })
    // },
    // 树型结构
    // getTableTreeList() {
    //   this.treeLoading = true
    //   this.treeList = [{
    //     name: '22年教育装备填报', level: 1, children: [
    //       { name: '表1 学校基本情况统计表', level: 2, type: 1, children: [] },
    //       { name: '表2 实验室管理人员情况统计表', level: 2, type: 2, children: [] },
    //       { name: '表3 实验室及功能教室用房情况统计表', level: 2, type: 3, children: [] },
    //       { name: '表4 教学仪器情况统计表', level: 2, type: 4, children: [] },
    //       { name: '表5 教学设备情况统计表', level: 2, type: 5, children: [] },
    //       { name: '表6 理科实验开出情况统计表', level: 2, type: 6, children: [] },
    //       { name: '表7 图书管理人员情况统计表', level: 2, type: 7, children: [] },
    //       { name: '表8 图书馆（室）情况统计表', level: 2, type: 8, children: [] },
    //       { name: '表9 当年教育技术装备投入情况统计表', level: 2, type: 9, children: [] }

    //     ]
    //   }]
    //   this.treeLoading = false
      // getDeployTreeList({itemId:this.$getCookie('projectId')}).then(response=>{
      //   this.treeList=response.data;
      //   this.treeLoading=false;
      // }).catch(error=>{
      //   this.treeLoading=false;
      // })
    // }
    // handleRevoke(row) {
    //   MessageBox.confirm('确定撤销吗', '确定', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     cancelButtonClass: 'qx',
    //     type: 'warning'
    //   }).then(() => {
    //     const loading = this.$loading({
    //       lock: true,
    //       text: 'Loading',
    //       spinner: 'el-icon-loading',
    //       background: 'rgba(0, 0, 0, 0.7)'
    //     })

    //     revoke(row.id).then(response => {
    //       Message({
    //         message: '操作成功',
    //         type: 'success',
    //         duration: 5 * 1000
    //       })
    //       loading.close()
    //       this.handleFilter()
    //     }).catch(function() {
    //       loading.close()
    //     })
    //   })
    // }
  }
}
</script>
  <style lang="less" scoped>
  .app-container{
    padding:10px 10px;
    height: 100%;
  }
  .filter-container {
    padding-bottom: 10px;

    .filter-item {
      display: inline-block;
      vertical-align: middle;
      margin-bottom: 10px;
      margin-right: 10px;
    }
    .date-filter-item{
      vertical-align: middle;
      margin-bottom: 10px;
      margin-right: 10px;
    }
  }
  .pagination-container{
    margin-top:0 !important;
    padding: 10px 16px !important;
  }
  #app-contain{
      height: 100%;
      width:100%;
    }
  </style>

